
  <!-- <div class="top"> -->

    <div class="flex-column inset-border mild-black" style="width:275px; height:275px">
      <div class="bet-header" style="margin-top:5px; margin-left:0px"> {{hand}} hand:${{price}} </div>
       <div class="flex-column">
            <div class="flex-column" style=" margin-top:20px; width:200px; box-sizing: border-box;">
                <ng-container *ngFor="let index of [1,2,3]">
                 <div class="flex">
                      <button  mat-mini-fab  *ngFor="let key of numbers[index]"  [@flash]="flashes[key].status" [color]="'warn'"
                               style="margin-left:{{marginLeft()}}px; margin-top:5px" (click)="clicked(key)"> {{key}}</button>
                      <button  mat-mini-fab  *ngIf="[1,2].includes(index) else next" [color]="'accent'" style="margin-left:12px; margin-top:5px"
                                   [@flash]="flashes[icons[index].value].status" [disabled]="icons[index].disabled" (click)="clicked(icons[index].value)"> <mat-icon>{{icons[index].value}}</mat-icon></button>
                      <ng-template #next>
                        <button  mat-mini-fab [disabled]="icons[index].disabled"  [color]="'accent'" style="margin-left:12px; margin-top:5px"  (click)="clicked(icons[index].value)"> <mat-icon>{{icons[index].value}}</mat-icon></button>
                      </ng-template>
                  </div>
                </ng-container>
            </div>
            <div class="flex">
              <button mat-flat-button  (click)="audioEnable=audioEnable?false: true" style="margin-top:10px; width:175px"
                [disabled]="false" [color]="'accent'" class="scale-p8"> Audio <mat-icon> play_circle</mat-icon></button>
                  <button  mat-mini-fab   [disabled]="icons[4].disabled" [color]="'accent'" style="margin-left:3px; margin-top:5px" (click)="clicked(icons[4].value)"> <mat-icon>{{icons[4].value}}</mat-icon></button>

            </div>
        </div>
      </div>
      <div style="width:25%; margin-top:20px" *ngIf="audioEnable">
            <ngx-audio-player [autoPlay]="false" muted="muted" style="background:yellow; !important"
                [playlist]="mssapPlaylist"
                [disablePositionSlider]="false"
                [displayRepeatControls]="true"
                [displayVolumeControls]="true"
                [displayVolumeSlider]="true"
                [displayTitle]="'adding-count'"
                (trackEnded)="onEnded($event)">
           </ngx-audio-player>
       </div>
       <!-- <div class="flex-column inset-border rich-black-bg" style="width:275px; height:275px "> -->
