import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
// import { DealerService } from '../../../services/deck.service';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

// import { sizeTrigger, listHSlideTrigger } from '../../../animations/basicTrigger';
import { imageDims,globalVars, maxCardsOut,MobileRouterService,MaxRowCards,maxCardsPerCol,
	   setNumberMargin, SpeedInSec,getMethodDesc,getTheLevel,decksMapping,calcAverageTimeElapsed,check_credit  } from '../../../services/global.service';

import { DealerService } from '../../../services/deck.service';
import {KeyPressedDm, BottomQuesButtonsPressed} from '../../store/bottom.model';
import {ActivatedRoute} from "@angular/router";
import {BaseListComponent} from '../../shared/list/component';
// import {MobileSendKeyRoute, MobileSendFlash, MobileSendUnFlash,BottomQuesButtonsSend,BottomResetMenuPressed} from '../../store/bottom.action.reducer';
import {MobileDrawCardAction, MobileDisplayCount, MobileDrawCardResetAction} from '../../store/cards.action.reducer';
import {MobileCountCardDataUnit} from '../../store/cards.model';
import { GroupButtons, Button, GroupButtonsEnum, GetCount} from '../../shared/classes/common';
// import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { CommonListAnimations} from '../../shared/animations/mobile-animations';
// import { GenericTopTopAction, FlipActionWrapper,GenericSlideCountTrain,GenericSlideConceptTrain, GenericSettingRestoreFromCach} from '../../store/generic.action.reducer';
import { GenericTopTopAction, GenericSlideLicenseTrain,GenericTimerAction,GenericUpdateTestTimer, GenericTimerElapsedAction,GenericIncrHands} from '../../store/generic.action.reducer';

import {MobileSendKeyRoute, BottomQuesButtonsSend,BottomResetMenuPressed, BottomAppleModify} from '../../store/bottom.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import {ThumbsTimerComponent} from '../thumbs-timer/component';

const IDLE = 0;
enum SettingActiveEnum {
	active,
	idle
};

@Component({
  selector: 'anms-mobile-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss','../../styles.scss'],
  animations: [
    // CommonListAnimations.anim,
    // CommonListAnimations.flash,
    // CommonListAnimations.drop,
    // CommonListAnimations.buttons,
		CommonListAnimations.flip,


  ]
})

export class MobileLicenseComponent  implements OnInit, OnDestroy {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
	@ViewChild("thumbs_timer") thumbsTimer: ThumbsTimerComponent;
  subscriptions=[];
  unit='';
  vars = {};
  count=0;
  default= {
    label: '30:00 minutes',
    timer:30
  }
  cardCount='0';
  // buttons: Array<Button>;
  dropShuffle=false;
  speed=0;
  userPossibleCount : {list:any, clist: any};
  chosen=5;
  license ={
    label:'',
    list: [5, 10, 20, 30, 60,1],
		disabled: {
			5:false,
			10:false,
			20:false,
			30:false,
			60:false,
			1:false
		},
    correct:0,
    wrong:0,
    timer:0, // 5 minutes *60 = 300 sec
    color:{1:'warn', 5: 'warn', 10: 'warn', 20:'warn', 30:'primary', 60:'warn'},
		dims:{width:0, height:0},
		enabled:false
  };
	flip=false;
  cardsOut=0;
  header='';
  list:any=[];
  image='';
  imageEnabled=false;
  status='none';
  flip_card="default";
  topWidth='';
  vh='';
  state:'flip'|'default'='default';
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': false,
 };
 die=false;
 topPos=400;
 topLeft=500;
 certBorder="fail-certification-border";
 settingState:SettingActiveEnum=SettingActiveEnum.idle;
 settingWasActive=false;
 number_panel= {margin_left:0, margin_top:0};
 cardsDrawn = {time:new Date().valueOf(), count:0, average:0.0};
 displayCards = false;
 active = {first:false, second: false};
///////
// constructor
///////
  constructor(
    private activatedRoute: ActivatedRoute,
    private dealerService: DealerService,
    private router: MobileRouterService,
    public store: Store<any>,
            ) {
          this.vars = globalVars;
          globalVars['mode']= 'license';
   }

////////

  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
    globalVars['mode']='';
    globalVars['speed'] = 1200;
    globalVars['testing'] = false;
		this.dealerService.shuffle();
		globalVars['cardsOut']=3;
  }

  reset():void {
    this.license.label = this.default.label;
    this.chosen=this.default.timer;
  }

	tevent(event) {
	  this.thumbsTimer.thumbsevent(event);
  }
/////////

/////////
// ngOninit task
/////////


fillupCountButs(expected:any):void {
	// console.log(`fillupCountBust::expected:${expected}`)
  this.userPossibleCount = GetCount(expected, true);
	// console.log(`expected:${expected}, list:${JSON.stringify(  this.userPossibleCount.list)}`)
  BottomQuesButtonsSend(this.store, this.userPossibleCount.list, 2400, this.userPossibleCount.clist[0]);
}
shuffle():void {
	// GenericTimerAction(this.store, {tick:GenericDataUnitEnum.stoptimer});
  this.dealerService.shuffle();
  this.draw(this.active.first? 0: 1);
}

eventHandler(e:any):void {
  switch(e) {
    case 'removed': {
			if (this.flip) {
				this.backpage();
				return;
			}
      // this.draw();
			return;
    } case 'drawn': {
			this.cardsDrawn.time=new Date().valueOf();
      this.fillupCountButs(this.dealerService.getReducedCount());
			GenericIncrHands(this.store);
			GenericUpdateTestTimer(this.store);
        break;
    } default: { // shuffle event
      // flash the shuffle for 10 sec.
      // this.shuffle.assign({disabled: false,name: 'shuffle', status: IDLE, color: 'warn'})
      // this.shuffle.flashit();
			//console.log(`rcvd shuffle event`)
      this.shuffle();
    }
  }
}

track(compared:any):void {
  if (compared) {
    this.license.correct++;
    return;
  }
  this.license.wrong++;
}

startTimer():void {
  // start 1sec timer
  // add the callback to decrement time
  // extract the digit from timer and added to license.timer
  this.license.correct=0;
  this.license.wrong=0;
	GenericTimerAction(this.store, {min: this.chosen, tick:GenericDataUnitEnum.downtimer, disable:true});
}

timerevent() {
	this.flip=true;
}

displayReport():void {
  const report = this.report();
  const status = report['status'];
  this.header=`Status:   ${status}`;
  this.list=report['list'];
  // this.image= `/assets/${status=='FAILED'?'failed':'licenses'}.jpeg`;
  this.image= `/assets/${status=='FAILED'?'failed':'certified'}.jpeg`;
}

divp2(numertor:any, denom:any) {
  return 	Number((numertor/denom).toFixed(2))
}

// calcAverageTimeElapsed(this.cardsDrawn)

report() {
	const cards =maxCardsOut();
	const ctime= this.chosen*60*1000; //chosen time in msec
	const timePerHand = cards * this.speed  + 3000;  // 5 second per response
	let exp_n_hands = Math.ceil(ctime/timePerHand);
	const handsPlayed =  this.license.correct + this.license.wrong;
	const accuracy  = this.divp2(this.license.correct, handsPlayed);
	      exp_n_hands = this.divp2(handsPlayed, exp_n_hands);
	const exp_rate = 0.85; // 85 pcnt
	const speed_status = exp_n_hands >= exp_rate;
	const accuracy_status = accuracy >= exp_rate;
	const overall_status = accuracy_status && speed_status;
	this.certBorder= overall_status? "pass-certification-border": "fail-certification-border";
	const lst = [];
  lst.push(`#correct responses:${this.license.correct}`);
  lst.push(`#wrong responses:${ this.license.wrong}`);
  lst.push(`accuracy percentage:${accuracy*100}%`);
	lst.push(`speed percentage:${exp_n_hands*100}%`);
  return {status: overall_status? 'PASS': 'FAILED', list: lst};
}

// user selecting a time for license


changeAllExcept(value, timer='400') {
	const disabled=this.license.disabled;
	for (const key in disabled) {
		if (key==timer) {
			disabled[key]=!value;
			continue;
		}
		disabled[key]=value;
	}
}
disableAllExcept(timer) {
  this.changeAllExcept(true, timer);
}
enableAll() {
  this.changeAllExcept(false);
}
// selection of 1 to 30 mintues timer
click(timer:any):void {
	this.license.enabled=true;
	this.dealerService.shuffle();
	this.disableAllExcept(timer);
	this.chosen=+timer;
	setTimeout(() => {
		this.startTimer();
	}, 200);
	BottomAppleModify(this.store, {disable:['lastIcon']});
	this.flip=false;
	this.displayCards = true;
	setTimeout(() => {
		this.draw();
	}, 300);
	this.active.first=true;
	this.slickModal.slickGoTo(1);
}

flipit() {
	  this.license.enabled=false;
		this.displayCards = false;
		if (this.state=="default") {
			this.slickModal.slickGoTo(3);
			this.state='flip';
			return;
		}
		this.enableAll();
		this.slickModal.slickGoTo(0);
		this.state='default';
}

 backpage() {
	 this.displayReport();
	 // this.flip_card="flip";
	 this.flipit();
	 setTimeout(() => {
		 this.imageEnabled = true;
		 // MobileSendKeyRoute(this.store,[{key:"forward", disabled: this.status=='FAILED'}, {key:'backward', flash:true}]);
		 BottomAppleModify(this.store, {enable:['backward'],flash:{backward: true}});

	 },1000); // rotate the frontpage
 }
  istate() {
    // let method;
		// switch(globalVars['method']) {
		// 	case 'jack': {method="Jack count"; break;}
		// 	case 'mit': {method="Classical hi/low count "; break;}
		// 	case 'single': {method="Single deck count"; break;}
		// 	default: {method="tens count"; break;}
		//
		// }
		const isSingle=globalVars['method']=='single';
		const image=globalVars['image'];
		const maxcards=isSingle? 12: image.rows*image.cols;
		const decks = isSingle? "": decksMapping();
    GenericTopTopAction(this.store,[getMethodDesc(), getTheLevel(), `${decksMapping()} certification test`]);
     BottomAppleModify(this.store, {enable:['backward',  'lastIcon'],   lastIcon:'settings'});
  }

  minutes(key) {
    if (key=='1') return 'minute';
    return 'minutes';
  }



  ngOnInit() :void {
    check_credit(this.router);
		globalVars['vpath']="certification";
		if (globalVars['speed'] > 800)
	     	globalVars['speed'] = 800;
		this.speed=globalVars['speed'];
		globalVars['cardsOut']= maxCardsOut();
		imageDims();
     globalVars['mode'] = 'license';
     globalVars['testing'] = true;

		 const margins = setNumberMargin();
 		this.number_panel.margin_left = margins.left;
 		this.number_panel.margin_top = margins.top;

		 // if (globalVars['method'] != 'single') {
			//  globalVars['ndecks']=6;
		 // }
     // this.vh = globalVars['mobileDevice']? "55": '70';
     // this.topWidth = globalVars['mobileDevice']? "100": '70';
		 if (globalVars['mobileDevice']) {
			 this.license.dims.width = 25;
			 this.license.dims.height = 25;
			 this.topWidth='100';
		 } else {
			 this.license.dims.width = 50;
	 	 	 this.license.dims.height = 50;
			 this.topWidth='70';
		 }
     // BottomResetMenuPressed(this.store);
     this.istate();
     this.subscriptions.push(
             this.store
               .select('mobileTopBottom')
               .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
               .subscribe((data: KeyPressedDm) => {
                 // this.processBottomKeys(data.key);
                 switch(data.key) {
									 case 'home' : {
											 this.die=true;
											 setTimeout(() => {
												 this.router.navigate('mobile/home');
											 }, 200);
										 break;
									 }
                   case 'backward': {
                      if(this.settingState==SettingActiveEnum.active) { return; }
                      if (this.license.timer==0 && this.state=='flip') {
                        this.reset();
												this.flipit();
                        this.imageEnabled=false;
                        BottomAppleModify(this.store, { enable:['lastIcon'], flash: {backward: false}});
                        return;
                      }
                      this.router.navigate('mobile/count-top');
                      break;
                    } case 'settings': {
													globalVars['setting'] = {
													 func:GenericSlideLicenseTrain,
													 mode:'license',
													 speed:globalVars['speed'],
													 route:'mobile/License'
												 };
												 this.router.navigate('mobile/setting-train');
          	        	}
                    }
               })
             );

     this.subscriptions.push(
             this.store
               .select('mobileTopBottom')
               .pipe(filter(event => event.typeof=== 'BottomQuesButtonsPressed'))
               .subscribe((data: BottomQuesButtonsPressed) => {
								 GenericTimerElapsedAction(this.store, calcAverageTimeElapsed(this.cardsDrawn));
                 const button = data.button;
                 const clist = this.userPossibleCount.clist;
                 const correct = clist.includes(button);
								 let id=0;
								 if (!correct) {
									 alert(`Correct count:${clist[0]}`);
								 }
                 this.track(correct);
								 id = this.active.first? 0: 1;
								 MobileDrawCardResetAction(this.store, id);
								 if (this.flip) {
									 this.backpage();
									 return;
								 }
								 id = id == 1 ? 0: 1;
								 if (id) {
									 this.active.first=false;
								   this.active.second=true;
								 } else {
									 this.active.first=true;
									 this.active.second=false;
								 }
								 this.slickModal.slickGoTo(id? 2: 1);
								 setTimeout(() => {
									 this.draw(id);
								 }, 200);
               }
             )
             );
             this.subscriptions.push(
                   this.store
                     .select('genericReducer')
                     .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                     .subscribe((data: GenericDataUnit) => {
                       if (data.action==GenericDataUnitEnum.settingDone) {
                         this.istate();
		                  	 this.settingWasActive=false;
                         // this.slickModal.slickPrev();
												 this.slickModal.slickGoTo(0);
                         BottomAppleModify(this.store, {enable:['backward','lastIcon']});
                         this.settingState=SettingActiveEnum.idle;

                       }

                     }
                   )
              );

     this.reset();
  }

  draw(id=0):void {
    BottomAppleModify(this.store, {disable:['lastIcon']});
    MobileDrawCardAction(this.store, false, id);

  }
	afterChange(event) {}

}
