import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription}  from 'rxjs';
import { SendUserHaveCredit } from '../../store/gen-setting.action.reducer';
import {KeyPressedDm} from '../../mobile/store//bottom.model'

import { RouterService, globalVars} from '../../services/global.service';
import {MobileSendKeyRoute, BottomAppleModify} from '../../mobile/store/bottom.action.reducer';
import { UserService} from '../../services/user.service';

import { Store } from '@ngrx/store';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { map, filter } from 'rxjs/operators';
import {GenericTopTopAction} from '../../mobile/store/generic.action.reducer';

const SIX_MONTHS_SUBSCRIPTION=false;

@Component({
  selector: 'anms-subscriptions',
  templateUrl: 'subscriptions.component.html',
  styleUrls: ['subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
@ViewChild('slickModal') slickModal: SlickCarouselComponent;


 prodObj: any;
 categories=[];
 slideConfig = {
  "slidesToShow": 1,
  "slidesToScroll": 1,
  "dots": false,
  "infinite": false,
  'arrows': false,
  'swipeToSlide': true,
  // centerMode:true
};
duration:'';
paymentState='';
cpage=SIX_MONTHS_SUBSCRIPTION? 5: 0;
checkoutIcon='add_circle';



productMap={
  0:'Yearly',
  1:'Monthly',
  2:'Weekly',
  3:'Daily',
  4:'Hourly'
};

productId= {
  'Yearly':'price_1QnpaLGHzOYpdSZnIS39cuA9',
  'Monthly':  'price_1Pa2xQGHzOYpdSZnsBP7Ykbp',
  'Weekly':   'price_1Pa2uuGHzOYpdSZnz4HTzvNg',
  'Daily':    'price_1Pa2sCGHzOYpdSZnlcvRp2YF',
  'Hourly':   'price_1Pa2kvGHzOYpdSZnFaviZlBw'
};

subscriptsKeys=[ 'description', 'price'];

products= {
  Yearly:{
    "description": "One year subscription",
    "price": "$99.99",
  },
  Monthly:
   {
     "description": "One month subsctiption",
     "price": "$49.99",
   },
   Weekly:
   {
     "description": "One week subscription",
     "price": "$19.99",

   },
   Daily:
   {
     "description": "One day subscription",
     // "price": 14.99,
     "price": "$5.99",
   },
  Hourly:
  {
    "description": "One hour subscription",
    "price": "$1.99",
  }
};
checkout=false;
confirmation= {
  Desc:'',
  Access:'',
  Total:''
};
productsLength=0;
productKeys = [];

 finalProduct={};
 paymentMessage='';
 subscriptions=[];
 vars=globalVars;
 disabled={forward:false,backward:true, done:false};
 constructor(
             private store : Store<any>,
             private routerService: RouterService,
             private userService: UserService
            ) {
               this.productKeys = Object.keys(this.products);
               this.productsLength = this.productKeys.length-1;
              }


  ngOnInit1() :void{
    setTimeout(() => {
      GenericTopTopAction(this.store, ['Choose your class recommended'], 'tigger');
    }, 200);
      // if (globalVars['credit']>0) { // this should never execute
      //     alert("You already have a credit.");
      //     this.routerService.navigate('mobile/train');
      // }
      this.subscriptions.push(
              this.store
                .select('mobileTopBottom')
                .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
                .subscribe((data: KeyPressedDm) => {
                  if (data.key=='backward') {
                    this.routerService.navigate('mobile/train');
                     return;
                  }
                })
              );
      BottomAppleModify(this.store, { disable:['forward'], enable:['menu', 'backward']});
      // this.productSelected=this.products['both'];

   }

   ngOnInit() :void {
     const keys = Object.keys(this.products);
     this.disabled['forward'] = this.productsLength  == this.cpage;
     setTimeout(() => {
       this.slickModal.slickGoTo(this.cpage);

     }, 100);
     // alert(`length:${keys.length}, cpage:${this.cpage}, disabled:${this.disabled['forward']}`)
     setTimeout(() => {
       GenericTopTopAction(this.store, ['Desktop, Mobile, Tablet, Laptop',
                                        'Note for Apple use Iphone 8 plus or, later models',
                                        'Note for Samsung use S3 or later models'
                                      ], 'tigger');
     }, 200);
       // if (globalVars['credit']>0) { // this should never execute
       //     alert("You already have a credit.");
       //     this.routerService.navigate('mobile/train');
       // }
       this.subscriptions.push(
               this.store
                 .select('mobileTopBottom')
                 .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
                 .subscribe((data: KeyPressedDm) => {
                   if (data.key=='backward') {
                     this.routerService.navigate('mobile/train');
                      return;
                   }
                 })
               );
       BottomAppleModify(this.store, { disable:['forward'], enable:['menu', 'backward']});
       // this.productSelected=this.products['both'];

    }
  ngOnDestroy() :void{
    for (const subs of this.subscriptions) {
	       // this.subscriptions[key].unsubscribe();
         subs.unsubscribe();

     }
  }


  confirm(){
    const confirmation=this.confirmation;
    const product        = this.finalProduct;
    confirmation.Desc    = product['description'];
    confirmation.Access  = "Mobile, desktop, tablet, laptop";
    confirmation.Total   = `$${product['price']}`;

  }




// Create a Checkout Session as soon as the page loads

  clicked(key) {
     switch(key) {
       case 'done': { // plus is pressed
         // this.disabled.backward=true;
         // this.disabled.forward=true;
              this.disabled.done=true;
              // alert(`page:${this.cpage}, productmap:${this.productMap[this.cpage]}`);
              this.finalProduct=this.products[this.productMap[this.cpage]];
              globalVars['product']=this.productId[this.productMap[this.cpage]];
              // console.log(`product:${globalVars['product']}, cpage:${this.cpage}`)
              this.routerService.navigate('checkout');
      	     return ;

       }
       case 'forward': {
         this.checkout=false;
         this.cpage++;
          this.slickModal.slickNext();
    	    if (this.cpage==this.productsLength) {
            this.disabled.forward=true;
          }
          this.disabled.backward=false;
    	    break;

       }
       case 'backward': {
	    // if (this.checkout) {
	    //     this.checkout=false;
	    //     this.slickModal.slickGoTo(0);
	    //   	return ;
	    // }
      this.checkout=false;
	    this.cpage--;
      if (this.cpage==0) {
        this.disabled.backward=true;
      }
      this.disabled.forward=false;
      this.slickModal.slickPrev();
        this.checkoutIcon='add_circle';
	    break;

       }
     }
  }

}
