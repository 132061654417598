import { Component, OnInit, OnDestroy } from '@angular/core';
import { MobileRouterService, globalVars, decksMapping} from '../../services/global.service';
import { Store } from '@ngrx/store';
import { GenericTopTopAction,GenericSlideBetTrain,GenericSlideConceptTrain,GenericSettingRestoreFromCach} from '../store/generic.action.reducer';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';
import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import {BottomAppleModify} from '../store/bottom.action.reducer';

enum SettingActiveEnum {
	active,
	idle
};
@Component({
  selector: 'anms-bet-summary',
  templateUrl: './bets.component.html',
  styleUrls: ['./bets.component.scss'],
  animations: [
      trigger('cardFlip', [
        state('default', style({
          transform: 'none'
        })),
        state('flipped', style({
          transform: 'rotateY(180deg)'
        })),
        transition('default => flipped', [
          animate('600ms')
        ]),
        transition('flipped => default', [
          animate('300ms')
        ])
      ])
    ]
})


export class MobileBetComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count'k', 'low count', 'Net count', 'Spread', 'comments'];
  subscriptions=[];
  vars=globalVars;
  enabled = false;
  settingWasActive=false;
  columns = [	'#hands', 'hand', 'won', 'lost', 'player', 'house'];

	T = {};
  tables={
   mit: {
		 '+10': [
				{'#hands': 'one-hand', hand: 'first', 'won': '483k', 'lost': '518k', 'player': '47%', 'house': '53%'},
		    {'#hands': 'two-hands', hand: 'first', 'won': '505k', 'lost': '509k', 'player': '50%', 'house': '50%'},
		    {'#hands': 'two-hands', hand: 'second', 'won': '504k', 'lost': '511k', 'player': '49%', 'house': '51%'},
		    {'#hands': 'three-hands', hand: 'first', 'won': '485k', 'lost': '500k', 'player': '48%', 'house': '52%'},
		    {'#hands': 'three-hands', hand: 'second', 'won': '502k', 'lost': '484k','player': '52%', 'house': '48%'},
		    {'#hands': 'three-hands', hand: 'third', 'won': '494k', 'lost': '503k', 'player': '49%', 'house': '51%'},
		 ],
		 '+12': [
		    {'#hands': 'one-hand', hand: 'first', 'won': '437k', 'lost': '523k', 'player': '41%', 'house': '59%'},
		    {'#hands': 'two-hands', hand: 'first', 'won': '513k', 'lost': '487k', 'player': '53%', 'house': '47%'},
		    {'#hands': 'two-hands', hand: 'second', 'won': '493k', 'lost': '484k', 'player': '51%', 'house': '49%'},
		    {'#hands': 'three-hands', hand: 'first', 'won': '464k', 'lost': '506k', 'player': '46%', 'house': '54%'},
		    {'#hands': 'three-hands', hand: 'second', 'won': '524k', 'lost': '495k', 'player': '53%', 'house': '47%'},
		    {'#hands': 'three-hands', hand: 'third', 'won': '504k', 'lost': '475k', 'player': '53%', 'house': '47%'},
		 ],
		 '+14': [
		    {'#hands': 'one-hand', hand: 'first', 'won': '443k', 'lost': '518k', 'player': '42%', 'house': '58%'},
		    {'#hands': 'two-hands', hand: 'first', 'won': '497k', 'lost': '472k', 'player': '53%', 'house': '47%'},
		    {'#hands': 'two-hands', hand: 'second', 'won': '472k', 'lost': '477k', 'player': '49%', 'house': '51%'},
		    {'#hands': 'three-hands', hand: 'first', 'won': '516k', 'lost': '471k', 'player': '55%', 'house': '45%'},
		    {'#hands': 'three-hands', hand: 'second', 'won': '517k', 'lost': '473k', 'player': '56%', 'house': '44%'},
		    {'#hands': 'three-hands', hand: 'third', 'won': '496k', 'lost': '477k', 'player': '52%', 'house': '48%'},
		 ]
	 },


};

  ndecks = globalVars['ndecks'];
  slideConfig :any= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   'showArrow': false,
   'swipeToSlide': false,
   "arrows": false

 };
 state: 'default'| 'flipped' ='default';
 die=true;
 settingCurrentlyActive=false;
 cols=[];
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);
      }
  tabClicked(event) {}
  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
   }
   flipit() {
       this.state= this.state=='default'? 'flipped': 'default';
   }
   afterChange(event:any):void {
   }
   clicked(event) {
     //console.log(event.tab.textLabel)
   }
   // get(deck, strength, hand) DataIf [] {
   //   return this.tables[deck][strength][hand];
   // }
	 istate() {
				const msg=[`Betting numbers for double-decks`, 'For running-count of 10, 12 and 14'];
				GenericTopTopAction(this.store, msg);
				BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],  lastIcon:'settings'});
				//BottomAppleModify(this.store, { enable:['backward','lastIcon'],  disable:['forward']});
	 }
   ngOnInit() :void{
		 this.istate();
		 this.cols = ['+10', '+12', '+14'];
		 this.T =this.tables['mit'];
		 setTimeout(() => {
			 this.die = false;

		 }, 100);
     this.subscriptions.push(
         this.store
           .select('mobileTopBottom')
           .pipe(filter(data => data.typeof=== 'KeyPressedDm'))
           .subscribe((data: KeyPressedDm) => {
						   if (data.target=='parent') {
								  this.die=true;
									return;
							 }
               switch(data.key) {
								 case 'home' : {
										 this.die=true;
										 globalVars['isAuthenticated'] = false;
										 setTimeout(() => {
											 this.router.navigate('mobile/home');
										 }, 200);
									 break;
								 }
                 case 'backward' : {
                    if(this.settingCurrentlyActive) { return; }
                        this.die=true;
                        setTimeout(() => {
                          this.router.navigate('mobile/train');
                        }, 200);
                      break;
                    } case 'settings': {

												globalVars['setting'] = {
												 func: GenericSlideBetTrain,
												 route:'mobile/bets'
											 };
												this.router.navigate('mobile/setting-train');
                        break;
                    }
               }
                        })
       );
      this.subscriptions.push(
            this.store
              .select('genericReducer')
              .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
              .subscribe((data: GenericDataUnit) => {
                if (data.action==GenericDataUnitEnum.die) {
                  this.die=true;
                  return;
                }
                if (data.action==GenericDataUnitEnum.settingDone) {
                      this.istate();
                      this.flipit();
											this.settingCurrentlyActive=false;

                }

              }
            )
       );

    }
  }
