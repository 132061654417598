import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MobileTimeLeftComponent} from './time-left/component';
import {AboutComponent} from './static/about.component';
import {MobileTrainTopComponent} from './train/top.component';
// import {ConceptTopComponent} from './train/concept.top.component';
import {MobileTrainSettingComponent} from './train/setting.train.component';
import {MobileAddingStrategyComponent} from './train/adding.strategy.component';
import {MobileNetcountStrategyComponent} from './train/netcount.strategy.component';
import {CountClassificationComponent} from './train/count.classification.component';
import {RulesComponent} from './train/rules.component';
import {MobileBetComponent} from './train/bets.component';
import {CountTopComponent} from './train/count.top.component';
import {MobilePlayComponent} from './train/play/play.component';

import {MobileLearningComponent} from './train/learning/learning.component';
import {MobileTestingComponent} from './train/testing/testing.component';
import {MobileLicenseComponent} from './train/license/license.component';

import {MobileMathTestingComponent} from './train/net-math/math.component';

import {MobileCardClassificationComponent} from './train/card.classification.component';
// import {ProxyComponent} from './proxy/proxy.component';

const MobileRoutes: Routes = [


{
  path: 'mobile/time-left',
  component: MobileTimeLeftComponent,
  data:{
   }
},


  {
    path: 'mobile/train',
    component: MobileTrainTopComponent,
    data:{ }
  },


  {
    path: 'mobile/setting-train',
    component: MobileTrainSettingComponent,
    data:{

     }
  },

  {
    path: 'mobile/home',
    component: AboutComponent,
    data:{ }

  },
  {
    path: 'mobile/count-top',
    component: CountTopComponent,
    data:{ }
  },

  // {
  //   path: 'mobile/concept-top',
  //   component: ConceptTopComponent,
  //   data:{ }
  // },
  {
    path: 'mobile/count-classification',
    component: CountClassificationComponent,
    data:{ }
  },
  {
    path: 'mobile/bets',
    component: MobileBetComponent,
    data:{ }
  },
  {
    path: 'mobile/rules',
    component: RulesComponent,
    data:{ }
  },
  {
    path: 'mobile/card-classification',
    component: MobileCardClassificationComponent,
    data:{ }
  },
  {
    path: 'mobile/adding-strategy',
    component: MobileAddingStrategyComponent,
    data:{ }
  },
  {
    path: 'mobile/netcount-strategy',
    component: MobileNetcountStrategyComponent,
    data:{ }
  },
  {
    path: 'mobile/License',
    component: MobileLicenseComponent,
    data:{
       page:'mobile/time-left-org'
     }
  },
  {
    path: 'mobile/lessons/:id',
    component: MobileLearningComponent,
    data:{ }
  },

  {
    path: 'mobile/tests/:id',
    component: MobileTestingComponent,
    data:{ }
  },
  {
    path: 'mobile/math-test/:id',
    component: MobileMathTestingComponent,
    data:{ }
  },
  {
    path: 'mobile/play',
    component: MobilePlayComponent,
    data:{
       page:'mobile/time-left-org'
     }
  },

  // {
  //   path: 'mobile/Certificate',
  //   component: MobileCertificateComponent,
  //   data:{ }
  // }
  // {
  //   path: 'Mobile/Tests/:id',
  //   component: MobileTestotherComponent,
  //   data:{ }
  // },
];

@NgModule({
  imports: [RouterModule.forChild(MobileRoutes)],
  exports: [RouterModule]
})
export class MobileRoutingModule { }
