 <ng-container *ngIf="enabled">
       <ng-container *ngIf="icon =='timelapse' else basic">
           <button mat-button [color]="color" style="margin-top:-4px;" [matTooltip]="'Response time'" (click)="clicked()" class="scale1p3">
               <mat-icon> {{icon}} </mat-icon> {{elabel}} sec
            </button>
       </ng-container>

     <ng-template  #basic>
         <mat-icon  (click)="clicked()" style="margin-top:-4px;" class="scale1p3"> {{icon}} </mat-icon> <span style="font-size:20px"> {{label}}</span>
     </ng-template>
  </ng-container>
