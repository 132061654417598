import { InsertCardsDataUnit ,CardsBase, MobileDrawCardDataUnit,PlayCardsDataUnit,
   MobileCountCardDataUnit,MobileCardsDrawnDataUnit, MobileDrawCardEnum} from './cards.model';
import { Action } from '@ngrx/store';

const INSERT_CARD_ACTION = 'insert-card';
const COUNT_CARD_ACTION = 'insert-card-count';
const DRAW_CARD_ACTION = 'insert-cards-draw';
const CARDS_DRAWN_ACTION = 'insert-cards-drawn';
const INSERT_PLAY_CARD_ACTION = 'insert-play-cards';


class CardsDrawnAction implements Action {
   readonly type = CARDS_DRAWN_ACTION;
   constructor(public payload: MobileCardsDrawnDataUnit) { }
}

class DrawCardAction implements Action {
   readonly type = DRAW_CARD_ACTION;
   constructor(public payload: MobileDrawCardDataUnit) { }
}

class CountCardAction implements Action {
   readonly type = COUNT_CARD_ACTION;
   constructor(public payload: MobileCountCardDataUnit) { }
}
////////////////////////////////
 class InsertCardAction implements Action {
    readonly type = INSERT_CARD_ACTION;
    constructor(public payload: InsertCardsDataUnit ) { }
}

class InsertPlayCardAction implements Action {
   readonly type = INSERT_PLAY_CARD_ACTION;
   constructor(public payload: PlayCardsDataUnit ) { }
}

type Actions = CountCardAction | InsertCardAction |DrawCardAction|CardsDrawnAction|InsertPlayCardAction;

export function MobileCardReducer(State = {}, action: Actions) {
   const actions =  [CARDS_DRAWN_ACTION,INSERT_CARD_ACTION, COUNT_CARD_ACTION,DRAW_CARD_ACTION,INSERT_PLAY_CARD_ACTION];
   return actions.includes(action.type)? action.payload: {};

};

export function MobileInsertCardAction (store: any, data: Array<CardsBase>, id: number) {
  store.dispatch( new InsertCardAction ({cards: data, id: id,typeof:'InsertCardsDataUnit', msg:''}));
}

export function MobileInsertStackBadgeAction (store: any, badge: string, id: number) {
  store.dispatch( new InsertCardAction ({cards: [badge], id: id,typeof:'InsertCardsDataUnit', msg:'SBADGE'}));
}

export function MobileFlashPlayerLabel(store: any, id: any) {
  store.dispatch( new InsertCardAction ({cards: [], id: id,typeof:'InsertCardsDataUnit', msg:'FLASH'}));
}
export function MobileUnflashPlayerLabel(store: any, id: any) {
  store.dispatch( new InsertCardAction ({cards: [], id: id,typeof:'InsertCardsDataUnit', msg:'UNFLASH'}));
}

export function MobileDropCardAction (store: any,  id: number, label="DROP" ) {
  store.dispatch( new InsertCardAction ({cards: [], id: id,typeof:'InsertCardsDataUnit', msg: label}));

}
export function MobileReplaceCardAction (store: any, data: Array<CardsBase>, id: number) {
  store.dispatch( new InsertCardAction ({cards: data, id: id,typeof:'InsertCardsDataUnit', msg: 'REPLACE'}));
}
export function MobileCardsMode (store: any, id: number) {
  store.dispatch( new InsertCardAction ({cards: [], id: id,typeof:'InsertCardsDataUnit', msg: 'PLAYER'}));
}
export function MobileCardsEnableAnimation (store: any, id: number) {
  store.dispatch( new InsertCardAction ({cards: [], id: id,typeof:'InsertCardsDataUnit', msg: 'ANIMATION-ENABLED'}));
}
export function MobileInsertPlayCardAction (store: any, cards: Array<any>, id: number, label:string, sum=0) {
  store.dispatch( new InsertPlayCardAction ({cards: cards, id: id,typeof:'PlayCardsDataUnit', label: label, sum:sum}));
}

export function MobileCardsBackgroundOff (store: any, id) {
  store.dispatch( new InsertCardAction ({cards: [], id: id,typeof:'InsertCardsDataUnit', msg: 'BACKGROUND'}));
}

export function MobileInsertBadgeAction (store: any,  id: number) {
  store.dispatch( new InsertCardAction ({cards: null, id: id,typeof:'InsertCardsDataUnit'}));
}

export function MobileDrawCardAction (store: any, shuffle=true, id=0) {
  store.dispatch( new DrawCardAction ({typeof:'MobileDrawCardDataUnit', type: MobileDrawCardEnum.cards, shuffle:shuffle, id:id}));
}
export function MobileDrawCardResetAction (store: any, id=0) {
  store.dispatch( new DrawCardAction ({typeof:'MobileDrawCardDataUnit', type: MobileDrawCardEnum.reset, shuffle:false, id:id}));
}
export function MobileDisplayCount(store:any, id=0) {
  store.dispatch( new DrawCardAction ({typeof:'MobileDrawCardDataUnit', type: MobileDrawCardEnum.badge,  shuffle:false, id:id}));
}
export function MobileCountCardAction (store: any, data: string) {
  store.dispatch( new CountCardAction ({count: data, typeof:'MobileCountCardDataUnit'}));
}
export function MobileCardsDrawnAction (store: any) {
  store.dispatch( new CardsDrawnAction ({typeof:'MobileCardsDrawnDataUnit'}));

}
export function MobileCardsPeerErase (store: any, id: number) {
  store.dispatch( new InsertCardAction ({cards: null, msg:'erase', id: id,typeof:'InsertCardsDataUnit'}));


}
