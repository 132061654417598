import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { Store } from '@ngrx/store';
import { catchError, retry } from "rxjs/operators";
import { environment as env } from '@env/environment';
import { LocalStorageService } from '../core/local-storage/local-storage.service';
import {print} from '../services/common.service';
import {sendServerErrorAction} from '../store/server.action.reducer';

import { User } from '../models/user';

let LOCAL=false;
let AWS=false;

const localRun = () => {
  LOCAL=true;
  AWS=false;
};
const bjbustRun = () => {
  LOCAL=false;
  AWS=false;
};

const awsRun = () => {
  LOCAL=false;
  AWS=true;
};

// localRun();
// bjbustRun();
awsRun();

const AHOST="api.jackcount.com";//amazon host
const EHOST =  "www.bjbust.com";//external but local internet host
// export const URL= LOCAL? EHOST_URL: "localhost:3000";
const IHOST="localhost:3000";


// const PROTO=LOCAL || AWS == false?"http://": "https://";


// const SERVER=`${PROTO}${URL}`;
// const PROD_API_ROOT_URL = SERVER;
//  const DEV_API_ROOT_URL = 'http://localhost:3000';
//
//
//  export const prodServerPath = (path) => PROD_API_ROOT_URL+path;
//  export  const devServerPath = (path) => DEV_API_ROOT_URL+path;
// import { prodServerPath, devServerPath} from './global.service';


const httpOptions = {
  headers: new HttpHeaders( {
    'Content-Type': 'application/json',
    //'Authorization': 'bbj-token'
  })
};



@Injectable({providedIn: 'root'})
export class UserService {

  // private authUrl       = AWS && !LOCAL?'/jlogin/login':'/api/login/';
  // private logoutUrl     = '/api/logout/';
  // private userPaidUrl   = AWS && !LOCAL?'/jkhan/khan':'/api/stripe-payment/';
  // private userTimerUrl  = AWS && !LOCAL?'/jtimecheck/timecheck':'/api/timecheck/';
  // private userEmail     = AWS && !LOCAL?'/jemail/email': '/api/sendPlainEmail/';
  // private activateUrl   = AWS && !LOCAL? '/jactivate/activate': '/api/activate'; // no longer used
  // private stripeCheckoutUrl   = AWS && !LOCAL?'/jcheckout/checkout':'/api/create-checkout-session/';
  // private checkoutStatusUrl   = AWS && !LOCAL?'/jkhan/khan':'/api/checkout-status/';

  private authUrl       = AWS && !LOCAL?'/jlogin/login':'/api/login/';
  private logoutUrl     = '/api/logout/';
  private userPaidUrl   = AWS && !LOCAL?'/jcheckout/checkout':'/api/stripe-payment/';
  private userTimerUrl  = AWS && !LOCAL?'/jtimecheck/timecheck':'/api/timecheck/';
  private userEmail     = AWS && !LOCAL?'/jemail/email': '/api/sendPlainEmail/';
  private activateUrl   = AWS && !LOCAL? '/jactivate/activate': '/api/activate'; // no longer used
  private stripeCheckoutUrl   = AWS && !LOCAL?'/jcheckout/checkout':'/api/create-checkout-session/';
  private checkoutStatusUrl   = AWS && !LOCAL?'/jkhan/khan':'/api/checkout-status/';




// server:api.jackcount.com auth-url-path:api.jackcount.com/login/jackcount-login
  private server="";
  getServer() {

    if (LOCAL) {
      this.server = "http://"+IHOST;
      return;
    }
    if (AWS) {
      this.server = "https://"+AHOST;
      return
    }
    this.server = "https://"+EHOST;

    // alert (`this.server: ${this.server}`)?
  }


  getPath(key) {
    switch(key) {
      case 'authUrl'      : return this.server+this.authUrl;
      case 'userPaidUrl'  : return this.server+this.userPaidUrl;
      case 'userTimerUrl' : return this.server+this.userTimerUrl;
      case 'email'        : return this.server+this.userEmail;
      case 'activate'     : return this.server+this.activateUrl;
      case 'checkout'     : return this.server+this.stripeCheckoutUrl;
      case 'checkout-status'     : return this.server+this.checkoutStatusUrl;



      default: return "/illegal/api/request";
    }
  }
  constructor(
      public store: Store<any>,
      private _http: HttpClient,
     private localStorageService: LocalStorageService
  ) {
     this.getServer();
    // if ( env.production ) {
    //   // prod mode
    //   this.authUrl = prodServerPath(this.authUrl);
    //   this.logoutUrl = prodServerPath(this.logoutUrl);
    // } else {
    //   // dev mode
    //   this.authUrl = devServerPath(this.authUrl);
    //   this.logoutUrl = devServerPath(this.logoutUrl);
    //
    // }
    // console.log(`this.server:${this.server}`)

  }

  setTokenParam() {
    // const token = this.localStorageService.getToken();
    return  {headers: new HttpHeaders().set('x-access-token',  localStorage.getItem('token'))};
  }

  processUserPayment (params: any): Observable<any> {
//    console.log(`process user payment: ${JSON.stringify(params, null, '\t')}`);
// console.log(`calling processUserPayment::${this.getPath('userPaidUrl')},  params:${JSON.stringify(params)}`);
    return this._http.post( this.getPath('userPaidUrl'), params )
      .pipe (
        catchError( this.handleError )
      )
  }

  processUserTimer (params: any): Observable<any> {
    // return this._http.post( this.userTimerUrl, params, this.setTokenParam())
    return this._http.post( this.getPath('userTimerUrl'), params )
      .pipe (
        catchError( this.handleError )
      )
  }

  credential(params) {
    // alert(`the path:${this.getPath('authUrl')}`);

    // console.log(`requesting xxx the following server:${this.server} auth-url-path:${this.getPath('authUrl')}, params:${JSON.stringify(params)}`);
    return this._http.post( this.getPath('authUrl'), params )
      .pipe(
        catchError( this.handleError )
      )
  }

  sendPlainEmail(email,subject, content) {
    let params =  {from: 'admin@bjbust.com', to: email, subject: subject, content: content};
    // console.log(`sendPlainEmail to path:${this.getPath('email')}, email:${email}`);
    this._http.post(this.getPath('email'), params)
    .pipe(catchError(this.handleError))
    .subscribe((data: any)=>{
      // console.log(`sendPlainEmail response:${JSON.stringify(data)}, email:${email}`);

               if (data.status == 'failed') {
                  alert (`following email didn't reach the destination: ${JSON.stringify(data.email)}`)
               }
   });
 }

  logoutUser( username: string, subscriptionTimeLeftInSec: number ): Observable<any> {
    let post_data = { 'email': username,  subscriptionTimeLeftInSec: subscriptionTimeLeftInSec};
    const token = this.localStorageService.getToken();
//    console.log(`logoutUser: token: ${JSON.stringify(token)}`);
    const headers =  new HttpHeaders().set('x-access-token',  token);
    return this._http.post( this.logoutUrl, {}, {headers: headers})
      .pipe (
        catchError ( this.handleError )
      )
  }

  handleError ( err ) {
    // console.log('handleerror------------------')
     console.log( err );
    // sendServerErrorAction(this.store, err);
    return Observable.throw ( err );
  }
  getRequest(url, callback) {
   const subscribe = this._http.post(url, {}).pipe(catchError(this.handleError))
    .subscribe((data) => {
        callback(data);
        subscribe.unsubscribe();
    });

  }

  getRequestWithParam(url, param, callback) {
   const subscribe = this._http.post(url, {param: param})
    .subscribe(
       {
         next: data => {
             callback(data);
             subscribe.unsubscribe();
         },
         error: error => {
           // console.log('handleerror------------------')
           // console.log( error );
           sendServerErrorAction(this.store, error);
         }
     });

  }

  getRequestWithParam_save(url, param, callback) {
   const subscribe = this._http.post(url, {param: param}).pipe(catchError(this.handleError))
    .subscribe((data) => {
        callback(data);
        subscribe.unsubscribe();
    });

  }



}
