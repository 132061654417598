<div class="scene">
   <div class="flip"  [@cardFlip]="state">
        <div class="face back" style="cursor:pointer">
          <a (click)="flipit()" >
            <mat-card  class="warn-background center ">
               {{data.back.stmt[1]}}
            </mat-card>
          </a>
        </div>
        <div class="face front" >
          <a (click)="flipit()" style="cursor:pointer">
                  <mat-card  class="{{fclass}} center" >  {{data.front.title}} </mat-card>
          </a>
        </div>
   </div>
</div>
<!-- <div  class="{{data.back.class}}">
      <p style="text-align:center"> {{data.back.stmt[0]}} </p>
      <p style="margin-left:30%;text-align:center"> {{data.back.stmt[1]}} </p>
</div> -->
<!-- <mat-card  class="{{bclass}} center ">
   {{data.back.stmt[1]}}
</mat-card> -->
