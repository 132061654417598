import { Component, OnInit, OnDestroy,ViewChild} from '@angular/core';
import {  MobileRouterService, globalVars,getMethodDesc,decksMapping,check_credit } from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import { GenericTopTopAction,GenericDisableSettingAction,GenericSlideConceptTrain,GenericGotoPrevAction,GenericSlideBetTrain,GenericSettingRestoreFromCach} from '../store/generic.action.reducer';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { GenericDataUnitEnum, GenericDataUnit} from '../store/generic.model';

import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';


import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes, query, stagger
} from '@angular/animations';

@Component({
  selector: 'anms-mobile-count-classification',
  templateUrl: './count.classification.component.html',
  styleUrls: ['../styles.scss','count.classification.component.scss'],
  animations: [
    trigger('flip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        //animate('400ms')
        animate('1000ms')
      ]),
      transition('flipped => default', [
        //animate('200ms')
        animate('500ms')
      ])
    ])
  ]
})


export class CountClassificationComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'Strong count', 'Weak count', 'Net count', 'Spread', 'comments'];
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  state='default';
  subscriptions=[];
  vars=globalVars;
  currentPage=0;
	settingCurrentlyActive=false;
  statements= {
       1: [
           [ // beginining of the deck

           {icon:'brightness_1', content:'Strong when the running count greater than 5'},
           {icon:'brightness_3', content:'Moderate when the running count greater than 2'},
           {icon:'blur_on', content:'Weak when the running count less than 2'},

         ],

         [ // middle of the deck
             {icon:'brightness_1', content:'Strong when the running count greater than 4'},
             {icon:'brightness_3', content:'Moderate when the running count greater than 1'},
             {icon:'blur_on', content:'Weak when the running count less than 1'}
         ],

         [ // end of the deck
           {icon:'brightness_1', content:'Strong when the running count greater than 3'},
           {icon:'brightness_3', content:'Moderate when the running count greater than 1'},
           {icon:'blur_on', content:'Weak when the running count 0'}
         ],


       ],


       2: [
           [ // beginining of the deck
             {icon:'brightness_1', content:'Strong when the running count greater than 10'},
             {icon:'brightness_3', content:'Moderate when the running count greater than 8'},
             {icon:'blur_on', content:'Weak when the running count less than 7'},
          ],

           [ // middle of the deck
               {icon:'brightness_1', content:'Strong when the running count greater than 8'},
               {icon:'brightness_3', content:'Moderate when the running count greater than 6'},
               {icon:'blur_on', content:'Weak when the running count less than 4'}
           ],

           [ // end of the deck
             {icon:'brightness_1', content:'Strong when the running count greater than 6'},
             {icon:'brightness_3', content:'Moderate when the running count greater than 4'},
             {icon:'blur_on', content:'Weak when the running count less than 3'}
           ],

       ],
       6: [
         [ // beginining of the deck
             {icon:'brightness_1', content:'Strong when the running count greater than 30'},
             {icon:'brightness_3', content:'Moderate when the running count greater than 24'},
             {icon:'blur_on', content:'Weak when the running count less than 18'},
         ],

         [ // middle of the deck
             {icon:'brightness_1', content:'Strong when the running count greater than 24'},
             {icon:'brightness_3', content:'Moderate when the running count greater than 20'},
             {icon:'blur_on', content:'Weak when the running count less than 12'},
         ],

         [ // end of the deck
             {icon:'brightness_1', content:'Strong when the running count greater than 18'},
             {icon:'brightness_3', content:'Moderate when the running count greater than 10'},
             {icon:'blur_on', content:'Weak when the running count less than 6'},
         ]
       ]

    };

  disabled={forward:false, backward:false};
  slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
     // "showArrow" : true,
     // "arrows": true,
     'swipeToSlide': true,
     arrows:false,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  extraMsg='';
  cpage='';
  die=false;
	settingWasActive=false;
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);
      }

  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }
   afterChange(event:any):void {
     // console.log( event);
     this.currentPage=event.currentSlide;
     switch(event.currentSlide) {
        case 0: { // method
          this.extraMsg="Beginining of the deck";
          break;
       }
       case 1: { // cardsOut
         this.extraMsg="Middle of the deck";
         break;
      }
      case 2: { // cardsOut
        this.extraMsg="End of the deck";
        break;
     }


    }
		if (this.currentPage==5) {
			this.disabled.forward=true;
		} else if (!this.currentPage) {
			this.disabled.backward=true;
			this.disabled.forward=false;
		} else {
			 this.disabled.backward=false;
				 this.disabled.forward=false;
		}
   }

   istate() {
     const _decks=globalVars['ndecks']
     const decks=globalVars['decksMapping'][_decks];
     GenericTopTopAction(this.store,
       [
           `${decks} prediction:`,
           "Defined in terms of threshold",
           "Thresholds derived from millions of hands played"
        ]

       );


      BottomAppleModify(this.store, {enable:['lastIcon', 'backward'], disable:['forward'], lastIcon:'settings'});
			this.extraMsg="Beginining of the deck";
			this.disabled.backward=true;
   }
   flipit() {
       this.state= this.state=='default'? 'flipped': 'default';
   }
	 clicked(key) {
		 if (key=='backward') {
			 this.slickModal.slickPrev();
       return;
		 }

		  this.slickModal.slickNext();
	 }
   ngOnInit() {
     check_credit(this.router);
    this.istate();
    globalVars['vpath']="threshold";

    this.subscriptions.push(
        this.store
          .select('mobileTopBottom')
          .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
          .subscribe((data: KeyPressedDm) => {
           switch(data.key) {
             case 'home' : {
                 this.die=true;
                 setTimeout(() => {
                   this.router.navigate('mobile/home');
                 }, 200);
               break;
             }
              case 'forward': {
                  if(this.settingCurrentlyActive) { return; }
                  this.slickModal.slickNext();
                  return;
              } case 'backward' : {
                  if(this.settingCurrentlyActive) { return; }
                  this.die=true;
                  setTimeout(() => {
                    this.router.navigate('mobile/train');
                  }, 200);
                  return;
              } case 'settings': {
								 this.settingCurrentlyActive=true;
                    globalVars['setting'] = {
                     func: GenericSlideBetTrain,
                     route:'mobile/count-classification'
                   };
                  this.router.navigate('mobile/setting-train');
              }
             }
         }
          )
      );
     this.subscriptions.push(
           this.store
             .select('genericReducer')
             .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
             .subscribe((data: GenericDataUnit) => {
               if (data.action==GenericDataUnitEnum.die) {
                 this.die=true;
                 return;
               }
             }
           )
      );
    }






  }
