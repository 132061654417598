import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import { MobileRouterService, globalVars,getMethodDesc, check_credit } from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute,BottomAppleModify} from '../store/bottom.action.reducer';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction} from '../store/generic.action.reducer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';


@Component({
  selector: 'anms-mobile-netcount-strategy',
  templateUrl: './netcount.strategy.component.html',
  styleUrls: ['../styles.scss']
})


export class MobileNetcountStrategyComponent  implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  //columns=['Count', 'High count', 'Low count', 'Net count', 'Spread', 'comments'];
    @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  subscriptions=[];
  gvars=globalVars;
  disabled={forward:false, backward:true};
  slidesCount=0;
  statements= [
       // [
       //   "Running count used for:",
       //   "Betting",
       //   "Hand's decisions like:",
       //   "Hit, split, double down and stand"
       // ],
       [
         "Running count should be calculated at the end of the hand",
         "The goal is to reduce the count",
         "to either a low or high card count",
         // "Press next->"
       ],
       [
         "If the count is labeled 'L' or 'H'",
         "the count is already is the reduced format",
         "no action is needed",
         // "Press next->"
       ],
       [
         "Count: '3 2'",
         "Smaller count is low card count '2'",
         "subtract out the smaller count '2' from '3'",
         "Count: '3 2' becomes '1H'",
         // "Press next->"
       ],
       [
         "Count: '4 8'",
         "Smaller count is high card count '4'",
         "subtract out the smaller count '4' from '8'",
         "Count: '4 8' becomes '4L'",
         // "Press next->"
       ],
        [
           "More Examples:",
           "count '5 7' becomes '2L'",
           "count '7 5' becomes '2H'",
           "count '5 9' becomes '4L'",
           "count '9 5' becomes '4H'",
           "count '8 8' becomes '0'",
         ]
       ];

  slideConfig= {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
     arrows:false,
     'swipeToSlide': true,
  };

  mssapPlaylist= [
  {
    title: 'Audio Title',
    link: 'assets/audio/First-sample.m4a',
    artist: 'Jack Count',
    duration: 90000
  }
];

audioCount = {
  ten:0,
  mit:0,
  advance:1
};

  setPlayList() {

    this.mssapPlaylist[0].link = `assets/audio/revised-high-low/netcount/Running-count-${this.audioCount.advance}.m4a`;

    switch(this.audioCount.advance) {
      case 1: {
        this.mssapPlaylist[0].title = "Revised high-low running count intro";
        this.mssapPlaylist[0].duration = 28000;
        return;
      }
      case 2: {
        this.mssapPlaylist[0].title = "Revised high-low running count 2nd example";
        this.mssapPlaylist[0].duration = 11000;
        return;
      }
      case 3: {
        this.mssapPlaylist[0].title = "Revised high-low running count 3rd example";
        this.mssapPlaylist[0].duration = 30000;
        return ;
      }
      case 4: {
        this.mssapPlaylist[0].title = "Revised high-low running count fourth example";
        this.mssapPlaylist[0].duration = 33000;
        return ;
      }
      case 5: {
        this.mssapPlaylist[0].title = "Revised high-low running count final example";
        this.mssapPlaylist[0].duration = 98000;
        return;
      }
      default:
       return ;
    }
  }

  extraMsg='';
  audioEnable = false;
  constructor(
    public store: Store<any>,
     private router: MobileRouterService
            ) {
	      // globalVars['state'] = 'train-concept';
        // super(store);
      }

  ngOnDestroy():void {
     for (const elm of this.subscriptions)
          elm.unsubscribe();
      MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }

   clicked(key) {

     if (key=='audio') {
       this.audioEnable = this.audioEnable? false: true;
       return;
     }
     this.audioCount.advance += key =='forward'? 1: -1;
     this.setPlayList();
     this.audioEnable = false;
     if (key=='forward') {
        this.slickModal.slickNext();
        return;
     } else if (key == 'backward') {
       this.slickModal.slickPrev();
       return;
     }
     this.router.navigate('mobile/concept-top');
   }

   afterChange(event:any):void {
       if (!event.currentSlide) {
         this.disabled.forward=false;
         this.disabled.backward=true;
         return;
       }
       if ((this.slidesCount-1)==event.currentSlide) {
         this.disabled.forward=true;
         this.disabled.backward=false;
         return;
       }
       this.disabled.forward=false;
       this.disabled.backward=false;
   }

   ngOnInit():void {
     check_credit(this.router);
     
      const list=[getMethodDesc(''),'Pick the smaller count',"Subtract out the smaller count"];
      if (globalVars['method']=='single') list.push("or when either high or low count is '9'");
      this.slidesCount=this.statements.length;
      GenericTopTopAction(this.store, list, 'tigger');
      BottomAppleModify(this.store, { enable:['backward','lastIcon'], disable:['forward'],lastIcon:'home'});
      globalVars['vpath']="concept/running-count";
      this.setPlayList();
      this.subscriptions.push(
            this.store
              .select('mobileTopBottom')
              .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
              .subscribe((data: KeyPressedDm) => {
                  const route = data.key=='home'? 'mobile/home': 'mobile/train';
                  setTimeout(() => {
                    this.router.navigate(route);
                  }, 200);
              })
          );
      }
  }
