import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {  MobileRouterService, globalVars, getMethodDesc,getTheLevel, decksMapping, check_credit} from '../../services/global.service';
import { Store } from '@ngrx/store';
import {BaseListComponent} from '../shared/list/component';
import {MobileSendKeyRoute, BottomAppleModify,BottomResetMenuPressed } from '../store/bottom.action.reducer';

import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../store/bottom.model';
import {ObjectNavigator} from '../shared/classes/object.navigator';
import {ListNavigator} from '../shared/classes/list.navigator';
import {Summary, Detailed} from '../shared/data/bets';
import {RuleTable} from '../shared/data/rules';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CommonListAnimations} from '../shared/animations/mobile-animations';
import {GenericTopTopAction} from '../store/generic.action.reducer';


enum StateEnum {
    top,
    lessons,
    tests
};


@Component({
  selector: 'anms-mobile-count-top',
  templateUrl: './count.top.component.html',
  styleUrls: ['../styles.scss'],
  animations: [
    CommonListAnimations.anim,
    CommonListAnimations.drop,
    CommonListAnimations.manim,
    CommonListAnimations.flip

  ]
})



export class CountTopComponent implements OnDestroy, OnInit  {
//  @ViewChild('countStepper') private countStepper: MatStepper;
  // subscription = {};
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  disabled={forward:false, backward:true};
  subscriptions=[];
  state: StateEnum = StateEnum.top;
  buttons=[];
  header='';
  buffer=[];
  pbuffer=[];
  opacity='opacity-0';
  ppage=-1;

  defaultClass={
    'Count': `enter`,
    'Simple-count': 'enter',
    'Running-count': `leave`,
    'Simple-running-count': `leave`,
    'count-subtraction-test': `leave`,
    'Continuous': 'leave',
    'Certification-practice': `leave`,
    'Practice': 'enter',
    'Tests': 'leave',
    'Audio': 'leave'

  };
  mouseClass={
  };
  chosen="Count";
  mouse(key) {
   this.mouseClass[key]='enter';
   this.mouseClass[this.chosen]='leave';
   this.chosen=key;
  }
  navigators= {
    top: ['Practice', 'Tests', 'Audio'],
    lessons: [],
    tests: []
  };

   lessonRouteMapping = {
     'Count': 'mobile/lessons/adding-count',
     'Running-count': 'mobile/lessons/net-count'
   };

   testRouteMapping = {
     'Simple-count': `mobile/tests/adding-count`,
     'count-subtraction-test': `mobile/math-test/1`,
     'Simple-running-count': `mobile/tests/net-count-1`,
     'Continuous': 'mobile/tests/Continuous',
     'Certification-practice': `mobile/tests/License-practice`
   };


  matcard={
    label:'',
    subtitle:'',
    content:''
  };
  enabled=false;
  audioEnabled = false;
  slideConfig= {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "dots": false,
   "infinite": false,
   arrows: false,
   'swipeToSlide': true,
 };
  gvars=globalVars;
  track=[];
  mssapPlaylist= [
  {
    title: 'Training Title',
    link: 'assets/audio/train/toplevel.m4a',
    artist: 'Jack Count',
    duration: 69000
  }
];
  setClass() {
     this.mouseClass={...this.defaultClass};
     // alert(`mouseClass:${JSON.stringify(this.mouseClass)}`);
  }

  setButtons():void {
    this.pbuffer=this.buttons;
    switch(this.state) {
      case StateEnum.top: {
        this.buffer=this.navigators.top;
        break;
      }case StateEnum.lessons: {
        this.buffer=this.navigators.lessons;
        break;
      }
      case StateEnum.tests: {
        this.buffer=this.navigators.tests;
        break;
      }
    }
  }

  constructor(public store: Store<any>,private router: MobileRouterService) {}


  ngOnDestroy() :void{
     for (const elm of this.subscriptions)
          elm.unsubscribe();
      MobileSendKeyRoute(this.store,[{key:"reset"}]);
   }

   afterChange(event:any):void {
     const cpage=event.currentSlide;
     const forward = cpage>this.ppage;
     if (forward) { // next arrow
         this.buttons=this.buffer;
     } else { // previous arrow
       this.buttons=this.pbuffer;
       this.state= StateEnum.top;
     }
     // this.color=this.changeColor(forward, this.buttons.includes('Continuous'));
     this.ppage=cpage;
     // this.chosen=cpage? "Up-count": "Count";
     this.chosen=  "Count";

     this.setClass();
   }

   topClicked(button:any):void {
     switch(button) {
       case 'Practice': {
         this.state=StateEnum.lessons;
         this.matcard.label='Changing level:';
         this.matcard.subtitle="100% accuracy at current-level";
         this.matcard.content="Go to maximum card's speed before changing number of cards";
         this.setButtons();
         break;
       }
       case 'Tests': {
         this.state=StateEnum.tests;
         this.setButtons();
         this.matcard.label='Testing your card count';
         this.matcard.subtitle="Increase the speed before adding more cards";
         this.matcard.content="Your goal is to reach the highest level possible";

         break;
       }
     }
     this.slickModal.slickNext();

   }

   lessonsClicked(button:any):void {
     this.router.navigate(this.lessonRouteMapping[button]);
   }


   testsClicked(button:any):void {

     this.router.navigate(this.testRouteMapping[button]);
   }

   clicked(button:any, type=0):void {
     // this.buttons=[];
     if (button=='Audio') {
       this.audioEnabled = this.audioEnabled? false: true;
       if (this.audioEnabled) {
         if (type==0) {
           this.mssapPlaylist[0].title = 'level-training';
           this.mssapPlaylist[0].link ='assets/audio/train/toplevel.m4a';
           this.mssapPlaylist[0].duration =69000;
         } else {
           this.mssapPlaylist[0].title = 'testing';
           this.mssapPlaylist[0].link =global['method']=='mit'? 'assets/audio/train/testingtop.m4a': 'assets/audio/jackcount/testingtop.m4a';
           this.mssapPlaylist[0].duration = global['method']=='mit'? 122000: 192000;
         }
       }
       return;
     }
     this.audioEnabled = false;
     this.mouse(button);
     switch(this.state) {
       case StateEnum.top: {
         this.topClicked(button);
         break;
       }
       case StateEnum.lessons: {
         this.lessonsClicked(button);
         break;
       }
       case StateEnum.tests: {
         this.testsClicked(button);
         break;
       }
     }

   }
   method() {
     switch(globalVars['method']) {
       case 'advance':
       case 'jack': return "Jackcount";
       case 'mit': return "Classical hi/lo";
       case 'ten': return "Count10";
       default: return "Single-deck-count"
     }
   }

   onEnded(e) {}
   ngOnInit() :void{
     check_credit(this.router);
      this.header = "Select from following:";
      this.buttons=this.navigators.top;
      switch(globalVars['method']) {
         case 'single':
         case 'advance':
         case 'jack': {
            this.navigators.lessons.push('Count','Running-count' );
             this.navigators.tests.push('Simple-count', 'count-subtraction-test', 'Simple-running-count', 'Continuous','Certification-practice', 'Audio');
             break;
          }
        default: {
          this.navigators.lessons.push('Count');
           this.navigators.tests.push('Simple-count','Continuous','Certification-practice', 'Audio');
           break;
        }
      }
      this.setClass();
      GenericTopTopAction(this.store, [getMethodDesc(''), getTheLevel(), `${decksMapping()} main training page`], 'tigger');
      BottomResetMenuPressed(this.store);
      BottomAppleModify(this.store, {enable:['forward', 'backward']});
      setTimeout(() => {
        this.opacity='opacity-1';
        this.setButtons();
      }, 500);

      this.subscriptions.push(
          this.store
            .select('mobileTopBottom')
            .pipe(filter(data => data.typeof=== 'KeyPressedDm' && data.target=='child'))
            .subscribe((data: KeyPressedDm) => {
               this.router.navigate(data.key == 'forward'? 'mobile/count-classification': 'mobile/train');
            })
        );

    }



  }
