


  <div style="margin-left:{{gvars['center-width']}}px; width:100%; margin-top:30px;" *ngIf="topEnabled">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" style='width:80%' (afterChange)="afterChange($event)" >
              <div ngxSlickItem class="slide">
                  <div class="card-list-width" >
                    <div class="flex-column" style="z-index:20">
                        <h7 style="margin-left:7px" *ngFor="let key of cardsNumber"> {{key}} high-cards</h7>
                    </div>

                      <div class="flex-row level-top" [@cards]="images.length" style="margin-top:20px">
                             <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                      </div>
                  </div>
              </div>
              <div ngxSlickItem class="slide">
                <div class="card-list-width" >
                  <div class="flex-column" style="z-index:20">
                      <h7 style="margin-left:7px" *ngFor="let key of cardsNumber"> {{key}} low-cards</h7>
                  </div>
                  <div class="flex-row level-top" [@cards]="images.length"  style="margin-top:20px">
                         <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                  </div>
                </div>
              </div>
              <div ngxSlickItem class="slide">
                <!-- <h7 class="landing-accent-bg" > Ignore cards: </h7> -->
                <div class="card-list-width" >

                    <h7  class="cards-header"> Three ignore cards: </h7>

                    <div class="flex-row level-top" [@cards]="images.length" style="margin-top:20px">
                           <img  src="{{cimage}}" *ngFor="let cimage of images" class="cards-spacing">
                    </div>
                </div>
              </div>
              <div ngxSlickItem class="slide">
                 <div class="flex-column">
                   <div class="flex-column" style="z-index:20">
                       <h7> 2 cards odds: </h7>
                       <h7 style="margin-left:7px" *ngFor="let key of cardsLikelihood"> {{key}} </h7>
                   </div>
                   <ng-container *ngFor="let i of [0,1]">
                       <div class="card-list-width" style="z-index:-20" >
                           <div class="flex-row level-top" [@cards]="allImages[i].length" style="margin-top:15px">
                                  <img  src="{{cimage}}" *ngFor="let cimage of allImages[i]" class="cards-spacing">
                           </div>
                       </div>
                    </ng-container>
                 </div>
              </div>

    </ngx-slick-carousel>

    <div style="margin-top:50px; margin-left:50px;">
              <div class="flex-row"  >
                      <button mat-flat-button  (click)="clicked('backward')"
                        [disabled]="disabled.previous" [color]="'warn'" class="scale-p8 margin-left-c-buttons"> <mat-icon> skip_previous</mat-icon></button>

                     <button mat-flat-button   (click)="clicked('forward')"
                          [disabled]="disabled.next" [color]="'warn'"  class="scale-p8 margin-left-c-buttons "> <mat-icon > skip_next </mat-icon></button>
              </div>
        </div>
  </div>
