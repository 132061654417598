import { Component, OnInit,OnDestroy } from '@angular/core';
import { Subscription}  from 'rxjs';
import { RouterService, globalVars} from '../../services/global.service';
import { UserService} from '../../services/user.service';
import {GenericTopTopActionDisable, GenericTopTopActionEnable} from '../../mobile/store/generic.action.reducer';
import {SendLogout,SendDisableSubscription,SendEnableSubscription} from '../../store/gen-setting.action.reducer';

import { SendUserHaveCredit } from '../../store/gen-setting.action.reducer';

import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import {KeyPressedDm} from '../../mobile/store//bottom.model'
import {MobileSendKeyRoute, BottomAppleModify} from '../../mobile/store/bottom.action.reducer';
// declare function Stripe(any): any;
import {loadStripe} from '@stripe/stripe-js';
const MINUTE=60*1000;
@Component({
  selector: 'anms-checkout',
  templateUrl: 'checkout.component.html',
  styleUrls: ['checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

 finalProduct={};
 subscriptions=[];
 vars=globalVars;
 interval:any;
 constructor(
             private store : Store<any>,
             private routerService: RouterService,
             private userService: UserService
            ) {
               // this.productKeys = Object.keys(this.products);
               // this.productsLength = this.productKeys.length;
              }
  async initialize() {
    // use publish key
    const stripe = await loadStripe(environment.stripeKey);
    const response = await fetch(this.userService.getPath('checkout'), {
      method: "POST",
       body: JSON.stringify({body:{productid:globalVars['product'], email:globalVars['email']} }),
       headers: { 'Content-type': 'application/json; charset=UTF-8' }
    });

    const { clientSecret } = await response.json();

    const checkout = await stripe.initEmbeddedCheckout({
      clientSecret,
    });

    //Mount Checkout
    checkout.mount('#checkout');
  }

   ngOnInit() :void{
     // setTimeout(() => {
     //   GenericTopTopAction(this.store, ['Choose your class recommended'], 'tigger');
     // }, 200);
       GenericTopTopActionDisable(this.store);
       SendDisableSubscription(this.store);

       this.subscriptions.push(
               this.store
                 .select('mobileTopBottom')
                 .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
                 .subscribe((data: KeyPressedDm) => {
                   if (data.key=='backward') {
                     this.routerService.navigate('mobile/train');
                      return;
                   }
                 })
               );
       BottomAppleModify(this.store, { disable:['forward'], enable:['menu', 'backward']});
       // this.productSelected=this.products['both'];
       localStorage.setItem("subscription", JSON.stringify({token: localStorage.getItem('token'), email:globalVars['email']}));
       this.initialize();
       // setTimeout(() => {
       //   this.checkStatus();
       // }, 5*MINUTE);
    }

  ngOnDestroy() :void{
    for (const subs of this.subscriptions) {
	       // this.subscriptions[key].unsubscribe();
         subs.unsubscribe();
     }
     GenericTopTopActionEnable(this.store);
     SendEnableSubscription(this.store);
    //  SendLogout(this.store);

  }
}
