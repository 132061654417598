import { Component, OnInit, OnDestroy, Input,Output, EventEmitter} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
// import { CommonListAnimations} from '../../shared/animations/mobile-animations';
import {InsertCardsDataUnit } from '../../store/cards.model';
import {trigger,state, style,transition,animate,query,stagger,keyframes} from '@angular/animations';
import { globalVars,imageDims } from '../../../services/global.service';
import {MobileCountCardAction, MobileCardsDrawnAction, MobileCardsPeerErase} from '../../store/cards.action.reducer';
import {MobileSendKeyRoute} from '../../store/bottom.action.reducer';
import { FlashButtons, FilterType, START_FLASH,  FADEOUT, FADEIN,nFlash} from '../../../services/common.service';
import {   DEALER_DISPLAY_ID} from '../../../store/switchcards.model';
const DROP='drop-cards';
@Component({
  selector: 'mobile-base-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['../../styles.scss'],
  animations : [
    // CommonListAnimations.mcards,
    // CommonListAnimations.flash,

    trigger('flash', [
      transition(`* => ${FADEIN}`, [
        style({ opacity: 0 }),
        animate(1200, style({ transform: 'translateX(0) scale(1)', opacity: 1 }))
      ]),
      transition(`* => ${FADEOUT}`, [
        animate(1900, style({ transform: 'translateX(0) scale(0.5)', opacity: 0 }))
      ])
    ]),
    trigger('blist', [
          transition('* => *', [
             // Initially the all cards are not visible
             query(':enter', style({ opacity: 0 }), { optional: true }),
               query(':enter', stagger('300ms', [
                 animate('1s ease-in', keyframes([
                   style({ opacity:  0, transform: 'translateX(-10px)', offset: 0 }),
                   style({ opacity:  0.5, transform: 'translateX(10px)', offset: 0.3 }),
                   style({ opacity:  1, transform: 'translateX(0)', offset: 1 }),
                 ]))]), { optional:  true }),

             query(':leave', stagger('300ms', [
               animate('1s ease-in', keyframes([
                 style({   opacity:  1, transform: 'translateX(50px)', offset: 0 }),
                 style({  opacity: .2, transform: 'translateX(300px)', offset: 0.7}),
                 style({  opacity: 0, transform: 'translateX(400px)', offset: 1}),

               ]))]), { optional:  true }),
             ])
     ]),
    trigger('list', [
          state('in', style({
                 opacity: 1,
                 transform: 'translateX(0)'
               })), // end of state
          transition('void => *', [
             style({
                     opacity: 0,
                     transform: 'translateX(-100px)'
                   }),
                      animate(300)
                        ]),
          transition(
              '* => void', [
                  animate(500, style({
                       transform: 'translateX(100px)',
                       //transform: 'rotate(180deg)',
                        opacity: 0
                  }))
          ])
    ]),


  ]

})

export class MobileBaseCardsComponent implements OnInit, OnDestroy  {

subscriptions=[];
cards=[];
badges=[];
cardsMargin='margin-right-';
savedList=[];
offset={height:0, width:0};
display=0;
vars:any;
marginLeft=0;
cardCount=0;
reduced={enabled:false, badge:'4l', top:0, left:0, cls:''};
nflash=new nFlash();
mobileDevice=false;

@Input('id') id: number;
@Input() label=''
@Input('mode') mode='flat';
@Output() event = new EventEmitter<{}>();
labelClass='';
ilocation={};
sbadge={enabled:false, value:'0'};
dealerBadgeMargin=0;
// bgClass="cards-makeup";
bgClass="";
image={width:0, height:0};
baseMarginLeft=5;
cardCountClass='card-counting-dressing';

// bgClass="";

constructor(public store: Store<any>) {  }

  ngOnInit():void {
    // endable the down arrow, disable up-arrow
    // MobileSendKeyRoute(this.store,[      {key:"switch"}    ]);

  this.cardsMargin += globalVars['netcount']? '4': '2';
  //console.log(`base-card, ngOnInit id:${this.id}`)
  this.vars = globalVars;
  //console.log(`vars[mobileDevice]: ${this.vars['mobileDevice']}`)
  this.marginLeft=globalVars['mode'] == 'license'? 5:0;
  this.mobileDevice=globalVars['mobileDevice'];
  const players=(id)=> id!=DEALER_DISPLAY_ID;
  this.cardCountClass = this.mobileDevice ? 'card-counting-dressing-mobile': 'card-counting-dressing';

  if (!players(this.id)) {
    this.labelClass="dealer-label";
  }

  imageDims();
  const image = globalVars['image'];
  const dims=image.dims;
  this.image = {height: image.card.height, width:image.card.width};
  const offset = this.offset;
  offset.width = Math.floor(image.card.width/3);
  offset.height = Math.floor(image.card.height/3);

  this.subscriptions.push(
    this.store
      .select('mobileCard')
      .pipe(filter(event =>  event.typeof=== 'InsertCardsDataUnit'))
      .subscribe((data: InsertCardsDataUnit) => {


        // console.log(`id::${data.id}, this.id::${this.id}, msg:${data.msg}`);

        if (data.msg=='BACKGROUND') {
          this.bgClass="";
          return;
        }
        if (data.id!==this.id) return;
        switch (data.msg) {

          case 'PLAYER' : {
            this.cardsMargin="margin-5px";
             return;
          } case 'REPLACE': {
            // this.cards[0] = data.cards[0]['card'];
            this.cards[1] = data.cards[0]['card'];
            // this.event.emit({id:this.id, action:'drawn'})
            return;
          } case 'DROP-1': {
            const length = this.cards.length;
            this.cards.splice(1,length-1);
            return;
          } case 'DROP': {
            this.erase();
            return;
          } case 'FLASH': {
            if (players(this.id) && !this.nflash.isActive()) {
                  this.flashit();
            }
            return;
          } case 'UNFLASH': {
            if (players(this.id) && this.nflash.isActive()) {
              this.unflash();
            }
            return;
          } case 'SBADGE': { // show badge
            setTimeout(() => {
              this.sbadge.enabled=true;
              this.sbadge.value=data.cards[0];
              // this.dealerBadgeMargin=Math.floor((this.image.width*this.cards.length)/2);
              // alert(`number of cards:${this.cards.length}`);
              this.dealerBadgeMargin = (this.image.width*this.cards.length) - (Math.floor(this.image.width/2)+5);

            }, globalVars['speed']+300);

            return;
          } case 'erase': {
            if (this.display) {
                this.erase();
           }
            return;
          }
        }
        if (data.cards) {
           this.process(data.cards);
           return;
         }
         this.pbadges(this.savedList,0);
      })
    );
    // this.calc();
    this.calBaseHeight();
  }

  flashit() {
    this.nflash.flash();
    this.labelClass="player-label";
  }

  unflash() {
    this.nflash.unflash();
    this.labelClass="player-label-none";
  }

  calBaseHeight() {
    const dims= globalVars['image'].dims;
    // const dims=image.dims;
    // this.image = {height: image.card.height, width:image.card.width};
    // const offset = this.offset;
    // offset.width = Math.floor(image.card.width/3);
    // offset.height = Math.floor(image.card.height/3);

    const factor=dims.includes('80') ? 13: 20;
    const base=dims.includes('80')? 40: 35;
    const leftMargin=dims.includes('80')?13:17;
    this.ilocation= {
      2: { top: factor, left: leftMargin},
      3: { top: factor, left: leftMargin+this.image.width+2},
      4: { top: factor*2, left: leftMargin},
      5: { top: factor*2, left: leftMargin+this.image.width+2},
      6: { top: factor*3, left: leftMargin},
      7: { top: factor*3, left: leftMargin+this.image.width+2},
      8: { top: factor*4, left: leftMargin},
      9: { top: factor*4, left: leftMargin+this.image.width+2},
      10: { top: factor*5, left: leftMargin},
      11: { top: factor*5, left: leftMargin+this.image.width+2},

    }
  }
  pbadges(list:any, speed:any):void {
    //console.log(`the list is ${JSON.stringify(list)}`);
    list=list.filter(x=> x.badge);
    // console.log(`the list after filter is ${JSON.stringify(list)}`);

    if (list.length==0) return;
    this.display++;
    // this.reduced.left=this.leftMargin(list.length-1)+15;
    const image=globalVars['image'];
    const offset = this.offset;
    this.reduced.left=this.leftMargin(list.length-1);
    //console.log(`cards-length:${list.length}, card-width:${image.card.width}, result:${this.reduced.left}, offset-width:${offset.width}`)

    // this.reduced.top=this.offset.height+27;

    this.reduced.cls=globalVars['mobileDevice']? "numbers-nav-reduced-mobile": "numbers-nav-reduced";
    this.reduced.cls= "numbers-nav-reduced";

    // this.transform(list);
    setTimeout(()=>{
      for(let i=0; i!=list.length;i++) {
        let badge=list[i].value;
        if (badge.includes('/')) {
          const spbadge=badge.split('/');
          this.badges.push(spbadge[0]);
          this.reduced.badge=spbadge[1];
          setTimeout(() => {
            this.reduced.enabled=true;
          }, (list.length+1)*300);
        } else {
          this.reduced.enabled=false;
          this.badges.push(list[i].value);
        }
      }
    }, speed);
    setTimeout(()=>{
      MobileCardsDrawnAction(this.store);
    }, speed+500);

  }

  erase() :void{
    let timeout=0;
    this.sbadge.enabled=false;
    this.sbadge.value='0';
    if (this.display ==2 ) { // badges are displayed so remove badges first
      this.badges=[];
      this.reduced.enabled=false;
      timeout=1200;
      this.display-=1;
    }
    if (this.cards.length) {
      setTimeout(() => {
        this.cards=[];
        this.display-=1;
        // if (this.id==0) {
          // MobileCountCardAction(this.store, 'null');
            this.event.emit({id:this.id, action:'removed'});
            this.cardCount--;
            // console.log(`cardCount:${this.cardCount}`);
            // if (! this.cardCount) {
            //   this.event.emit({id:this.id, action:'allRemoved'});
            // }
        // }
      }, timeout);
    }

  }


 leftMargin(index:any):any {
   // return this.width.base+index*this.width.length+ (index?this.width.extra*index:0);
   const image=globalVars['image'];
   const offset = this.offset;
   // 5 is margin between cards
   return (index?index*5:0) + (index*image.card.width) + offset.width;
 }

  handleCards(elm) {
    const card = elm.card;
    this.cards.push(card);
    this.event.emit({id:this.id, action:'drawn'});
    this.cardCount++;
  }
  process(list:any) :void{

    let speed=globalVars['speed'];
    this.display++;

    if (this.badges.length) {
      setTimeout(()=>this.badges=[], 500);
      speed+=500;
    }
    let i=globalVars['play']? 0: -1; //disable it if play is on
    for(const elm of list) {
      i+=1;
      if (i==0 && this.badges.length==0) {
        this.handleCards(elm);
        continue;
      }
      setTimeout(()=>this.handleCards(elm), speed);
      speed+=globalVars['speed'];
    }
    speed -= globalVars['speed'];
    if (globalVars['testing']) { // don't show the badges during testing
      this.savedList = list;
      return;
    }
    list=list.filter(x=> x.badge);
    if (list.length) {
      this.pbadges(list, speed);
    }
  }

  ngOnDestroy():void {
     for (const elm of this.subscriptions)
          elm.unsubscribe();
   }

}
