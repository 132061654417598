<div style="width:100%;margin-left:{{vars['center-width']}}px; height:100%; margin-top:30px" *ngIf="!die" >
      <div class="flex-column" style="height:80%; width:100%">
          <anms-thumbs-timer  #thumbs_timer style="margin-left:100px">   </anms-thumbs-timer>
              <div class="scene" style="margin-top:20px">
              		     <div class="flip" [@cardFlip]="state">
                                 <div class="face back">
                                   <ng-container *ngIf="state!='default'">
                      		             <ng-container *ngFor="let row of matrix.row">
                                            <div class="flex-row" >
                                              <ng-container *ngFor="let col of matrix.row">
                                                   <anms-mobile-flip-card    style="margin-right:4px;cursor:pointer" [id]="row*matrix.size+col" >  </anms-mobile-flip-card>
                                               </ng-container>
                                            </div>
                                         </ng-container>
                                 </ng-container>

                                 </div>
                                <div class="face front">
                                   <ng-container *ngIf="state=='default'">
                                         <ng-container *ngFor="let i of matrix.row">
                                              <div class="flex-row">
                                                <ng-container *ngFor="let col of matrix.row">
                                                   <anms-mobile-flip-card style="margin-right:4px;cursor:pointer"  [id]="i*matrix.size+col" >  </anms-mobile-flip-card>
                                                 </ng-container>
                                              </div>
                                           </ng-container>
                                   </ng-container>

                              </div>
                    </div>
	           </div>
      </div>
      <anms-bottom-numbers style="margin-top:20px; height:20% !important" (thumbsEvent)="tevent($event)"> </anms-bottom-numbers>

</div>
