import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
// import { DealerService } from '../../../services/deck.service';
import { Observable,Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';

import { isJack,globalVars, MobileRouterService,getMethodDesc, check_credit } from '../../../services/global.service';
import { DealerService} from '../../../services/deck.service';

import {BaseListComponent} from '../../shared/list/component';
import {BottomQuesButtonsPressed} from '../../store/bottom.model';

import {KeyPressedDm} from '../../store/bottom.model';
import {FADEOUT, FADEIN} from '../../../services/common.service';

import {Shuttle, GroupButtons, Button,GroupButtonsEnum, CreateUniqueRandomCount} from '../../shared/classes/common';

import {trigger,state,style,transition,animate,keyframes,query,stagger} from '@angular/animations';
import { CommonListAnimations} from '../../shared/animations/mobile-animations';
import { GenericDataUnitEnum, GenericDataUnit} from '../../store/generic.model';
import { GenericTopTopAction, FlipActionWrapper,GenericTimerAction, GenericIncrHands, GenericTimerDisableAction} from '../../store/generic.action.reducer';
import {MobileSendKeyRoute, BottomQuesButtonsSend,BottomResetMenuPressed, BottomAppleModify} from '../../store/bottom.action.reducer';
import {ThumbsTimerComponent} from '../thumbs-timer/component';


const IDLE = 0;
class IterateList {
   list=[];
   current:any;
   constructor() { }
   push(e) { this.list.push(e);}
   pop() { this.current= this.list.shift();}
   size() { return this.list.length;}
   empty() { return this.size()?false: true;}
   get(key) { return this.current[key];}
//   list() { return this.list;}
};


class SetBorder {
	size:number;
	current: number;
	constructor(size=12) {
	   this.size=size;
	   this.current=-1;
	}

  set(store) {
     this.current++;
     FlipActionWrapper(store, {focus:1}, this.current);
  }
  reset() { this.current=-1;}
	get() {return  this.current;}
};

 @Component({
  selector: 'anms-math-mobile-testing',
  templateUrl: './math.component.html',
  //styleUrls: ['./math.component.scss', '../../styles.scss'],
  styleUrls: ['./math.component.scss'],
  animations: [

    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        animate('600ms')
      ]),
      transition('flipped => default', [
        animate('300ms')
      ])
    ])
  ]

  })

// export class MobileMathTestingComponent extends BaseListComponent implements OnInit, OnDestroy {
export class MobileMathTestingComponent implements OnInit, OnDestroy {
  @ViewChild("thumbs_timer") thumbsTimer: ThumbsTimerComponent;


  subscriptions=[];
  unit='';
  label='';
  vars = {};
  count=0;
  drawnCount=0;
  headerEnabled=false;
  cardCount='0';
  header;
  buttons: Array<Button>;
  reroute=false;
  buttonsEnabled=false;
  tbuttons : GroupButtons;
  userPossibleCount : {list:any, clist: any};
  shuttle800: Shuttle;
  rCell= {
    list: [],
    index:0
  };
  nButtons=12;
  badges=[];
  offset={top:0, left:0};
  level=0;
  scale = '';
  backPageOn=false;
  netCount='';
  die=false;
  state: 'default'| 'flipped';
  iterateList: IterateList;
  setBorder:SetBorder;
  matrix={row:[], size:0};
  numMargin='';
///////
// constructor
///////
  constructor(
    private dealerService: DealerService,
    private router: MobileRouterService,
    public store: Store<any>,
            ) {
          this.vars = globalVars;
          globalVars['state']= 'train-testing';
          this.buttons=[];
	        this.iterateList = new IterateList();
	        this.setBorder=new SetBorder();
          for (let i=0; i!=4; i++) {
            this.buttons.push( new Button(`${i}`));
          }
          const tbuttons =[];
          for (let i=0; i!=2; i++) {
            tbuttons.push(new Button(`0`));
            tbuttons[i].color = i?'warn':'accent';
            tbuttons[i].changed = false;
          }

          this.tbuttons = new GroupButtons(tbuttons, GroupButtonsEnum.one, ['correct', 'wrong'])
          this.tbuttons.setLength(2);
          this.shuttle800= new Shuttle(1000); //changed to 1000
          const modifyIcon = (button:any, icon:any) => {
            if (button.changed == false || button.name =='0') {
              return;
            }
            if (button.icon) {
              button.icon = false;
            } else {
              button.icon = true;
              button.iconValue=icon;
            }
          }
          this.shuttle800.add(()=> {
            modifyIcon(this.tbuttons.get('correct'), 'thumb_up');
          });
          this.shuttle800.add(()=> {
            modifyIcon(this.tbuttons.get('wrong'), 'thumb_down');
          });
   }
////////
tevent(event) {
  this.thumbsTimer.thumbsevent(event);
}

  ngOnDestroy() :void{
   for (const subscription of this.subscriptions) {
       subscription.unsubscribe();
     }
     MobileSendKeyRoute(this.store,[{key:"reset"}]);
     this.shuttle800.destroy();
      globalVars['subtraction-test']=false;
  }

updateTestNum() {
  this.iterateList.pop();
  BottomQuesButtonsSend(this.store, this.iterateList.get('list'), 4000, this.iterateList.get('cvalue'));

  this.setBorder.set(this.store);

}

populateReductionCell(base=0) :void{

  for (const item of CreateUniqueRandomCount(this.matrix.size*this.matrix.size)) {

     FlipActionWrapper(this.store, {
       front: {title:item.count, class:'enter medium-font' },
       back: {stmt:item.stmt, class:'warn medium-font'}
       }, base++);
     this.iterateList.push({cvalue:item.cvalue, list:item.list});
  }
  this.setBorder.reset();
  this.updateTestNum();
  GenericIncrHands(this.store);
}


track(compared:any) :void{
    this.tbuttons.track(compared);
}
flipit() {
	  this.state= this.state=='default'? 'flipped': 'default';
}
ngOnInit() :void {
     check_credit(this.router);
     // GenericTimerDisableAction(this.store);
     this.state="default";
      BottomResetMenuPressed(this.store);
     globalVars['subtraction-test']=true;
     setTimeout(() => {
       GenericTimerAction(this.store, {min:0, tick:GenericDataUnitEnum.uptimer});
     }, 200);
     const list=[getMethodDesc('math-test')];
     // if (isJack()) {
     //   list.push("Note 'L' stands for low-cards", "'H' is stands for high-cards");
     // }
     list.push("For each buttons shown in the green", "Select the correct running-count", "If confused, click on each button to see the Correct value");
      GenericTopTopAction(this.store,list, 'tigger');

    this.matrix.row= globalVars['mobileDevice']? [0,1,2]: [0,1,2,3];
    // this.matrix.row= [0,1,2];

    this.numMargin= globalVars['desktop']? '0px': '10px';
    this.matrix.size=this.matrix.row.length;
    globalVars['vpath']="count/tests/math";
    this.subscriptions.push(
         this.store
           .select('mobileTopBottom')
           .pipe(filter(event => event.typeof=== 'KeyPressedDm'))
           .subscribe((data: KeyPressedDm) => {
        		   if (data.key=='backward') {
                    this.die=true;
                    setTimeout(() => {
                      this.router.navigate('mobile/count-top');
                    }, 200);
                    return;
                   }
             })
         );


           this.subscriptions.push(
                 this.store
                   .select('genericReducer')
                   .pipe(filter(event =>event.typeof=== 'GenericDataUnit'))
                   .subscribe((data: GenericDataUnit) => {

                       if (data.action==GenericDataUnitEnum.timerReset) {
                             this.tbuttons.reset();
                           return;
                         }
                   }
                 )
            );

    this.subscriptions.push(
         this.store
         .select('mobileTopBottom')
         .pipe(filter(event => event.typeof=== 'BottomQuesButtonsPressed'))
         .subscribe((data: BottomQuesButtonsPressed) => {
			      let fail=false;
            const cvalue=this.iterateList.get('cvalue');
            // alert(`correct-value:${cvalue}, data.button:${data.button}`);
            if ( cvalue == data.button) {
               this.track(true);
             } else {
    			     fail=true;
               FlipActionWrapper(this.store,  { trigger:true}, this.setBorder.get());
               this.track(false);
             }
			       if ( this.iterateList.empty()) {
    			      const timeout=fail? 2000: 0;
    			      setTimeout(()=> {
                   FlipActionWrapper(this.store,  { reset:true}, -1);
      				     this.flipit();
                   setTimeout(() => {
                     this.populateReductionCell();
                   }, 100);

    			      }, timeout);
    			   } else {
               const timeout = fail? 2000 : 0;
               setTimeout(() => {
      			     this.updateTestNum();
               }, timeout);
             }
            }
          )
    );
    BottomAppleModify(this.store, {enable:['backward'], disable:[ 'forward','lastIcon']});
    setTimeout(() => {
      this.populateReductionCell();
    }, 200);
  }
}
