import {Injectable} from '@angular/core';
import { Subject} from 'rxjs';
import { globalVars, ResetGlobalvars,decksMapping,isJack, Random} from './global.service';
// import {strengthModel} from './socket.io.service';


import { print } from './common.service';

export enum CardCut {
   begin,
   middle,
   end
};

class  CardMakeUp {
  constructor () {}
  suits= [ 'clubs','diamonds','hearts','spades'];
  i2a={11:'J',12:'Q',13:'K',1:1,2:2,3:3,4:4,5:5,6:6,7:7,8:8,9:9,10:10};
  ivalue(card) :number { return (card>10)?10:card; };
  suits_map={
      hearts:'\u2665',
      clubs:'\u2663' ,
      diamonds:'\u2666',
      spades:'\u2660',
      '\u2665':'hearts',
      '\u2663':'clubs',
      '\u2666':'diamonds',
      '\u2660':'spades'
    };

    isymbols={
      1:'1', 2:'2', 3:'3', 4:'4',
      5:'5', 6:'6', 7:'7', 8:'8',
      9:'9', 10:'10', 11:'J',12:'Q',13:'K',
    };

  toString(card):string {
            return (card.num>10?this.i2a[card.num]:card.num).toString()+':'+card.suit;
  }

  lower(card): {} {
       if (card.num > 10) { card.num= this.i2a[card.num] };
       return card;
  }

  classify(card) :number{  // previously map
        let val;
         // if passed as object; Nan, Not a number
         if (isNaN(card)) val=this.ivalue(card['num']);
         // passed as integer
         else val=this.ivalue(card)
         //print ('val:'+val)
         if (val >1 && val<7)     return 1;
         if (val >=7 && val<10)     return 2;
         return 10;
     }
}

const getRandomIntInclusive=(min:number,max:number) => {
     min = Math.ceil(min);
     max = Math.floor(max);
     return Math.floor(Math.random() * (max - min + 1)) + min;
};


class   RandomCard {
  values = {
      high:{index:4, 'list':[1,10,11,12,13]},
      low:{index:4, 'list':[2,3,4,5,6]},
      other:{index:2, 'list':[7,8,9]}
  };

  cardMakeUp:CardMakeUp;

  constructor() { }
  config():void { this.cardMakeUp=new CardMakeUp();}


  get(type:string) :{} {
        let suit=this.cardMakeUp.suits[getRandomIntInclusive(0,3)];
        let num=this.values[type]['list'][getRandomIntInclusive(0,this.values[type]['index'])];
        return {num:num,ssuit:this.cardMakeUp.suits_map[suit], suit:suit,rank:this.cardMakeUp.i2a[num]}
  }
};

class  SingleDeckOfCards {
  //values=[1,2,3,4,5,6,7,8,9,10,11,12,13];
  values = {
     'low and high cards the same, difficulty(1)': [1,2,3,4,5,6,7,8,9,10,11,12,13],
     'high cards more frequent, difficulty(2)':           [5,3,4,2,10,11,12,13,1,10,7,8,13],
     'low cards more frequent, difficulty(2)':            [5,3,4,2,2,3,4,13,1,10,7,8,13],
     'only low and high cards, difficulty(3)':           [1,2,10,3,11,4,12,5,13,6,1,2,10],
  }
  cardMakeUP:CardMakeUp;
  randomCard:RandomCard;
  cards:any [] ;

  get(value:number,suit:string):{} {

    return {num:value,ssuit:this.cardMakeUP.suits_map[suit], suit:suit,rank:this.cardMakeUP.i2a[value]};
  }


  // building deck
  _build():void {
      const values =  [1,2,3,4,5,6,7,8,9,10,11,12,13];
//print(`weight: ${weight} , values: ${values}`);
      for (let suit of this.cardMakeUP.suits) {
         for (let value of values) {
            this.cards.push(this.get(value,suit));
         }
      }
  }


  constructor() {
      this.cardMakeUP=new CardMakeUp();
      this.cards=[];
      this._build();
  }

  slice() { return this.cards.slice();}
  display(card:any): void {
     console.log('card is: num('+card.num+') ssuit('+card.ssuit+') suit('+card.suit+') rank('+card.rank+')');
  }

 }


function randomArrayShuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

const shuffleDecks = () => {
  const decks = [];
  for (let i=0; i!=globalVars['ndecks'];i++) {
    const cards=new SingleDeckOfCards();
    //const shuffled=randomJs.shuffle(cards.slice());
    const shuffled=randomArrayShuffle(cards.slice());
    decks.push(...shuffled);
  }
  return randomArrayShuffle(decks);
}


export class ShuffleTime {

   thold={
        1:   {1:15,2:15,3:20},      // single deck number of players <--- number of cards out
        2:   {1:20,2:25,3:30,4:32}, // double decks number of players <--- number of cards out
        4:   {1:20,2:25,3:30,4:32}, // six decks number of players <--- number of cards out
        6:   {1:20,2:25,3:30,4:32} // six decks number of players <--- number of cards out
    };


   constructor (private ndecks:number, private nplayers:number) {}
   shuffle(len:number):boolean {
      return len <= this.thold[this.ndecks][this.nplayers];
   }
}

const HIGHCARDS=[1,10,11,12,13,14];
const LOWCARDS=[2,3,4,5,6];
const DONTCARE=[7,8,9];

export class CardCount {
  hi:number;
  lo:number;
  cardMakeUp:CardMakeUp;
  adjusted = '';
  list=[];
  trace: boolean;
  lastPoped:any;
  countHigh = false;
  dealerCardHidden = true;
  prevCount='';
  d1card=0;
  cards = 0

  dontResetCount = false;

  constructor(traceCardCount=true) {
       this.hi=0;
       this.lo=0;
       this.cardMakeUp=new CardMakeUp();
       this.trace = traceCardCount;
  }

  classify(card) { return this.cardMakeUp.classify(card); }

  flush() {
   this.list=[];
   this.reduceit();
   this.cards = 0;
  }

  setDealerHiddenCard() {
    this.list.push(this.get());
    this.d1card=0;
  }

  count(card):void {
    // console.log(` d1card:${globalVars['d1card']}`);
    // const timeout=globalVars['play'] && this.list.length? 100: 1000;
      const c = this.classify(card);
      this.lo += c==1?1:0;
      this.hi += c==10?1:0;
      this.dontResetCount = false;

      if (globalVars['play']) {
        const length = this.list.length;
         if (this.cards==3) {
           // capture dealer hidden card
           if (c==1) this.d1card=1;
           else if (c==10) this.d1card=10;
           else this.d1card=0;
         }
         switch(this.cards) {
           case 0 :
           case 1 :
           case 2 :
                { this.list.push(this.get()); break;}
           case 3 :
                 { this.list.push(this.list[2]); break;}
           // case 3 : { this.list.push(this.getMinusHidden()); break;}
           default: {
             this.list.push(this.getMinusHidden()); break;
           }
         }
         this.cards++;
      }

  }

// 2 ways to push the count to the list; one by this.count or this.push
  toString(low, hi) {
    return low+hi ? `${hi} ${low}`: '0 0';
  }

  reset() {
       if (this.dontResetCount) return;
       this.hi=0;
       this.lo=0;
       this.list=[];
       this.adjusted = '';
       globalVars['cardCount']='';
  }
  // it return the count
  tensCount() {
    const total10=globalVars['ndecks']*20;
    return `${total10-this.hi}`;
  }
  get() {
      if (this.mitMethod()) {
           return this.mitCount( this.hi, this.lo);
      }
      // console.log(`cardCount get:lo:${this.lo}, hi:${this.hi}, ${this.toString(this.lo, this.hi)}`)
      return  this.toString(this.lo, this.hi);
  }
  mitMethod() { return globalVars['method']=='mit';}
  tenMethod() { return globalVars['method']=='ten';}

  mitCount(high, low) {
    if (low==high) return '0';
    const diff = Math.abs(high-low);
    return high>low ? `-${diff}`: `+${diff}`

  }

/*
 * it reduces the count and converts it to net count
 */
  getReduced() {
    let hi = this.hi;
    let lo = this.lo;
    if (globalVars['method'] == 'mit') {
      return this.mitCount(hi,lo);
    }
    if (hi==lo) return '0 0';
    const diff = Math.abs(hi-lo);
    return hi>lo ? `${diff} 0`:  `0 ${diff}`;
  }

  isCountTooHigh() {
    if (['advance', 'jack'].includes(globalVars['method'])) {
      const thold = globalVars['ndecks']==6? 35: 20;
      const rvalue = Math.abs(this.hi-this.lo) >=thold;
      return Math.abs(this.hi-this.lo) >=thold;
    } return false;
  }

    getMinusHidden() {
      let hi = this.hi;
      let lo = this.lo;
      if (this.d1card==1) lo -=1;
      if (this.d1card==10) hi -=1;
      if (this.mitMethod()) {
           return this.mitCount( hi, lo);
      }
      // console.log(`cardCount get:lo:${this.lo}, hi:${this.hi}, ${this.toString(this.lo, this.hi)}`)
      return  this.toString(lo, hi);
    }

  updateGlobalCardCount() {
    if (this.dontResetCount ) return;
    if (this.list.length==0) {
     globalVars['cardCount'] = this.prevCount;

   } else {
     this.prevCount = this.list.shift();
     globalVars['cardCount'] = this.prevCount;
   }

  }

  updateGlobalWithReduceCardCount() {
    if (this.dontResetCount ) return;
    globalVars['cardCount'] = this.getReduced();
  }

  reduceit() {
    if (globalVars['method']=='ten') return;
    if (this.hi > this.lo) {
      this.hi -= this.lo;
      this.lo=0;
    } else {
      this.lo -= this.hi;
      this.hi=0;
    }
  }

}

/*
 * single class responsible for creating cards and providing some accessory logic for
 * determining number, string and suit
 */
class ModifyCards {
   modified=false;
   modifiedList = [];
   constructor() {}
   modify(cards, list) {
    const length = list.length;
    for(let index=0; index!=length; index++) {
        const num = list[index];
        let foundIndex= this.findIndex(cards, num);
        if (foundIndex === -1) {
           foundIndex=0;
           cards[0].num=num;
        }
        this.modifiedList.push(cards.splice(foundIndex,1)[0]);
      }
      this.modified = false;
      const rval = this.modifiedList.concat(cards);

      return rval;
   }
   findIndex(cards, num) {
      const list = num === 10 ? [10,11,12,13,14] : [num];
      for (let i =0; i!=cards.length; i++) {
          if (list.includes(cards[i].num)) return i;
      }
      return -1;
    }
    swapCard(cards, from, to) {
       const t_card = cards.splice(to, 1);
       const f_card = cards.splice(from-1, 1);
       cards.push(t_card[0]);
       cards.splice(to,0, f_card[0]);
       return cards
    }
}
enum CardStatusEnum {
   begin,
   middle,
   end,
   done
};

class CutThreshold {
  _table: {};
  _ctable:{};
  constructor() {
    this._table={
       1 : {begin: 14, middle: 24, end:38},
       2 : {begin: 25, middle: 50, end:85},
       6 : {begin: 80, middle: 180, end:275}
    };
  }
  build(deck) {
    this._ctable = this._table[deck];
  }
  get(count) {
//print(`count: ${count}, end: ${this._ctable['end']},middle:${this._ctable['middle']},begin:${this._ctable['begin']}`);
    if (count > this._ctable['end']) return  CardStatusEnum.done;
    if (count > this._ctable['middle']) return  CardStatusEnum.end;
    if (count > this._ctable['begin']) return  CardStatusEnum.middle;
    return CardStatusEnum.begin;
  }
}

export class Cards {
    cards:any [];
    cardCount:CardCount;
//    shuffleDecks:ShuffledDecks;
    cardMakeUp:CardMakeUp;
    auto : boolean;
    modifyList = [];
    c: number;

    countAfterShuffle: number;
    cutThreshold: CutThreshold;
    _shuffleCount =0;
    _cut: string;
    strength: string;
    decks=1;
    outOfDeck=0;
    overWriteCards=null;
    cardsNotDealt() { return this.countAfterShuffle === this.cards.length;}

    build() {
      this.decks=globalVars['ndecks'];
      this.cards=shuffleDecks();
      this.outOfDeck=0;
      this.cardCount=new CardCount();
      this.countAfterShuffle = this.cards.length;
      this.cutThreshold.build(globalVars['ndecks']);
    }

    cardCut() {
     switch(this.cutThreshold.get(this.outOfDeck)) {
       case CardStatusEnum.done: return 'done';
       case CardStatusEnum.begin: return 'begin';
       case CardStatusEnum.middle: return 'middle';
       case CardStatusEnum.end: return 'end';
     }
    }

    shuffled() {return this.outOfDeck==0;}

    classify(card) { return this.cardCount.classify(card);}

    insertOverWrite(cards) {
      this.overWriteCards=cards;
    }

    overWrite() {
      let i=0;
      for (let card of this.overWriteCards) {
        this.cards[i].num = card;
        this.cards[i].rank = card;
        i++;
      }

    }


    modifyLowOrHigh(count, list, offset=0) {
      const len = list.length-1;
      for (let i=0;i!=count;i++) {
         let index = Random(0, len);
         const num = list[index];
         this.cards[i+offset].num = num;
         this.cards[i+offset].rank = this.cardMakeUp.i2a[num];
      }
    }

    modifyMix(count, list) {
      let lcount = count & 0xf;
      let hcount = (count>>4) & 0xf;
      let offset = 0;
      count = hcount + lcount;
      while(count) {
        let index = Random(0,1);
        if (index==0 && lcount==0) index = 1;
        else if (index==1 && hcount==0) index = 0;
        this.modifyLowOrHigh(1, list[index], offset++);
        if (index) hcount -=1;
        else       lcount -=1;
        --count;
      }


    }

    modifyNCards() {
      const modifyDeck = globalVars['modifyDeck'];
      switch(modifyDeck.type) {
        case 'low': {
            this.modifyLowOrHigh(modifyDeck.count, [2,3,4,5,6]);
             break;
        } case 'high': {
            this.modifyLowOrHigh(modifyDeck.count, [10,11,12,13,1]);
             break;
        } default: {
          this.modifyMix(modifyDeck.count, [[2,3,4,5,6], [10,11,12,13,1]]);
          break;
        }
      }
    }
    //adjust(params: {}) { this.cardCount.adjust(params);}
    shuffle() {
       // console.log(`shuffling when out of decks are:${this.outOfDeck}, decks:${globalVars['ndecks']}`)
       this.cards=shuffleDecks();
       if (globalVars['modifyDeck'].count)  {
          this.modifyNCards();
       }
       if (this.overWriteCards) {
         this.overWrite();
       }
//print(`Cards:: shuffle is called, cards.length: ${this.cards.length}`);
       this.countAfterShuffle = this.cards.length;
       this.cardCount.reset();
       this.outOfDeck=0;
       this._shuffleCount++;
    }
    shuffleCount() { return this._shuffleCount;}
    resetShuffleCount() { this._shuffleCount=0;}

    reduceit() { this.cardCount.reduceit();}
    list() { return this.cards.slice();}
    insert(cards) { this.cards = cards;}


    constructor( ) {
      this.cutThreshold = new CutThreshold();
      this.build();
      this.cardMakeUp=new CardMakeUp();
      this.auto =globalVars ['auto-shuffle'];

    }
    time2shuffle() {
//print(`cards out: ${this.outOfDeck}`);
       return this.cutThreshold.get(this.outOfDeck ) === CardStatusEnum.done;
    }
    enoughCards(nhands) {
      // print(`enough-cards::this cards length: ${this.outOfDeck}, hands: ${nhands}`);
      return this.cutThreshold.get(this.outOfDeck + 5*(nhands+1)) === CardStatusEnum.done ? false: true;
    }
    cut() {
      switch(this.cutThreshold.get(this.outOfDeck)) {
        case CardStatusEnum.begin : return 'begin';
        case CardStatusEnum.middle : return 'middle';
        case CardStatusEnum.end : return 'end';
        case CardStatusEnum.done : return 'done';
      }
    }
    nxtCard() { return this.cards[0];}
    cardsLeftInDeck() { return this.cards.length;}
    transferStrengthCut() {
       globalVars['strength'] = this.strength;
       globalVars['cut']      = this._cut=='begin'? 'beginning': this._cut;
    }

    get() {
       if (globalVars['ndecks'] != this.decks) {
         this.build();
       }

       const length = this.cards.length;
       const card=this.cards.shift();
       this.outOfDeck++;
       // print(`Class::cards::outOfDeck: ${this.outOfDeck}, cards.length:${this.cards.length}`);
       this.cardCount.count(card);
       return card;
    }

    configureCards(count) {
       const cards= count.includes('-') || count.includes('H')? [10]: [2,3,4,5,6] ;

       let _count = 0;
      if (count.includes('-') || count.includes('+')) { _count = +count.slice(1, count.length);}
      else _count = +count.slice(0, count.length-1);
      let i=0;
      let loop=100000;
      const o_cards = [...this.cards];
      while(true) {
        const _card = this.cards[i];
        const card = this.classify(_card);
        if (cards.includes(card)) {
          console.log(`card:${card}, included:${JSON.stringify(cards)}`)
          this.cards.splice(i,1);
          _count -= 1;
          if (cards.includes(10)) {
            this.cardCount.hi +=1;

          } else {
            this.cardCount.lo +=1;
          }
          if (_count == 0) break;
        }
        i+=1;
        if (i==this.cards.length) {
          this.cards = [...o_cards];
          this.shuffle();
          i=0;
        }
        if (--loop == 0) {
          alert(`count is not set`);
        }
      }
      const value = count.slice(0, count.length-1);
      if (globalVars['method'] =='mit') globalVars['cardCount'] = count;
      else  globalVars['cardCount'] = count.includes('L')? `0 ${value}` : `${value} 0`;
      this.cardCount.dontResetCount = true;
    }


    single(n) {
      return this.get();
    }
    num(card):number { return this.cardMakeUp.ivalue(card);}
    getCount() {
        return this.cardCount.get();
     }
//    setCount(count: number) { return this.cardCount.set(count); }
    getReducedCount() { return this.cardCount.getReduced();}
    resetCount():void {      this.cardCount.reset();}
    printNums():void {
     let lst=[];
     for (let i=0;i!=this.cards.length;i++) {
        lst.push(this.cards[i].num);
     }
    }


  length() {
    return this.cards.length;
  }
};


@Injectable({providedIn: 'root'})
export class DealerService {
  cards:Cards;
  cardsWeight='';
  ndecks:number;
  nplayers:number;
  randomCard:RandomCard;
  bypass:boolean;
  modifiedDeck:boolean;
//  cu:CalCommonUtilBase;

  constructor() {
    ResetGlobalvars();
    this.cards = new Cards();
  }

  insertOverWrite(cards) {
    this.cards.insertOverWrite(cards);
  }

  setDecks() {
      globalVars['decks'] = decksMapping();
      this.cards = new Cards();
      this.cards.shuffle();
//print([`setDecks called with decks set to ${globalVars['ndecks']}`]);
  }
/////////////////////
 getNcards() {
  if (  globalVars['certificate']['enabled'] ) {
    return globalVars['certificate']['cards'][globalVars['ndecks']];
  }
  return   globalVars['cardsOut'];
}
///////////////////

get() {
     let rval=[]
     if (globalVars['reset']) { this.cards.shuffle(); }
     const lst=[];
     const cardsLeft = this.cards.cardsLeftInDeck();
     const ncards = this.getNcards();
     if (ncards >= cardsLeft) {
             // alert(`Going to shuffle the deck(1)`);
             this.cards.shuffle();
     }
     for(let i=0;i!=ncards;i++)  lst.push(this.cards.get());
     return lst;
}


  singleCard(n) {
    return this.cards.single(n);
  }



  // resetCount() {     this.cards.resetCount();}
  getCount() {
       return this.cards.getCount();
  }

  isCountTooHigh() {
    return this.cards.cardCount.isCountTooHigh();
  }

  //setCount(count:number) { this.cards.setCount(count);}
  getReducedCount() { return this.cards.getReducedCount(); }
  //display(card):void { this.cards.display(card);}
  shuffle() {
    // console.log(`deck-service shuffle called`)
    this.cards.shuffle();}
  shuffleCount() { return this.cards.shuffleCount();}
  resetShuffleCount() { this.cards.resetShuffleCount();}
  reduceit() { this.cards.reduceit(); }
  // time2shuffle
  time2shuffle() { return this.cards.time2shuffle();}
  enoughCards(nhands) {
     return this.cards.enoughCards(nhands);
  }
  classify(card) { return this.cards.classify(card);}
  cardsLeftInDeck() { return this.cards.cardsLeftInDeck();}
  cardsList() { return this.cards.list();}
  cardCut() { return this.cards.cardCut();}
  time() { return Date().split(' ')[4]; }
  cardsInsert(cards) { this.cards.insert(cards);}
  nxtCard() {
    const rval =this.cards.nxtCard();
    return rval > 10 ? 10 : rval;
  }
 updateGlobalCardCount() {this.cards.cardCount.updateGlobalCardCount();}
 flushCardCount() { this.cards.cardCount.flush()}
 shuffled() { return this.cards.shuffled();}
 outOfDeck() { return this.cards.outOfDeck;}
 getCut() { return this.cards.cut(); }
 cardsNotDealt() { return this.cards.cardsNotDealt();}
 transferStrengthCut() { this.cards.transferStrengthCut();}
 setDealerHiddenCard() {this.cards.cardCount.setDealerHiddenCard();}
 updateGlobalWithReduceCardCount() {this.cards.cardCount.updateGlobalWithReduceCardCount();}
 configureCards(count) { this.cards.configureCards(count);}
}
